<template>
  <div class="mt-4 mb-4">
    <div class="card statement-of-costs">
      <div class="card-header">
        <span class="align-middle">{{ $t('statement_of_costs_table.title') }}</span>
      </div>
      <div class="alert alert-info notice">
        <strong>{{ $t('statement_of_costs_table.info.title') }}</strong>
        <ul>
          <li>{{ $t('statement_of_costs_table.info.messages_1') }}</li>
          <li>{{ $t('statement_of_costs_table.info.messages_2') }}</li>
          <li>{{ $t('statement_of_costs_table.info.messages_3') }}</li>
        </ul>
        <template v-if="allDetailsVisible">
          <strong>{{ $t('statement_of_costs_table.info.example_title') }}</strong>
          <ul>
            <li v-html="$t('statement_of_costs_table.info.example_messages_1_html')" />
            <li v-html="$t('statement_of_costs_table.info.example_messages_2_html')" />
            <li v-html="$t('statement_of_costs_table.info.example_messages_3_html')" />
          </ul>
        </template>

        <div v-if="allDetailsVisible">
          <img
            class="img-fluid"
            src="../../images/statement-of-costs-example.png"
          >
        </div>
        <a
          v-if="!allDetailsVisible"
          href="#"
          class="alert-info"
          @click.prevent="showAllDetails"
        >
          <strong>{{ $t('statement_of_costs_table.info.expand_details') }}</strong>
        </a>
      </div>
      <table class="table table-sm table-bordered resources-table">
        <thead class="thead-light">
          <tr>
            <th class="tight">#</th>
            <th class="tight">{{ $t('statement_of_costs_table.table.cost_type') }}</th>
            <th class="tight">{{ $t('statement_of_costs_table.table.transport_mode') }}</th>
            <th>{{ $t('statement_of_costs_table.table.from') }}</th>
            <th>{{ $t('statement_of_costs_table.table.to') }}</th>
            <th class="tight">{{ $t('statement_of_costs_table.table.departure_date_time') }}</th>
            <th class="tight">{{ $t('statement_of_costs_table.table.arrival_date_time') }}</th>
            <th class="amount">{{ $t('statement_of_costs_table.table.amount') }}</th>
            <th class="amount">{{ $t('statement_of_costs_table.table.invoice_evidence') }}</th>
            <th class="table-actions" />
          </tr>
        </thead>
        <tfoot>
          <tr>
            <td colspan="10">
              <button
                type="button"
                :disabled="actionsDisabled()"
                class="btn btn-sm btn-primary align-middle add-row"
                @click.prevent="addItem"
              >
                <span class="glyphicons add" /> Add row
              </button>
            </td>
          </tr>
        </tfoot>
        <tbody>
          <template
            v-for="(item, itemIdx) of items"
            :key="`cost-item-data-${itemIdx}`"
          >
            <tr :class="{ 'for-destruction table-danger': item._destroy }">
              <td class="tight">{{ item.nr }}.</td>
              <td class="tight">{{ fCostType(item) }}</td>
              <td class="tight">{{ fTransportMode(item) }}</td>
              <td>{{ fJourneyFrom(item) }}</td>
              <td>{{ fJourneyTo(item) }}</td>
              <td class="tight">{{ fDateTime(fDepartureDate(item)) }}</td>
              <td class="tight">{{ fDateTime(fArrivalDate(item)) }}</td>
              <td
                class="amount"
                :rowspan="itemRowspan(item)"
              >
                {{ fAmount(item) }}
              </td>
              <td :rowspan="itemRowspan(item)">
                <ul
                  v-if="Array.isArray(item.files) && item.files.length > 0"
                  class="files-list m-0"
                >
                  <li
                    v-for="(file, fileIdx) of item.files"
                    :key="`cost-item-data-${itemIdx}-file-${fileIdx}`"
                    class="text-truncate"
                  >
                    <a
                      :href="file.url"
                      target="_blank"
                    >
                      {{ file.filename }}
                    </a>
                  </li>
                </ul>
              </td>
              <td
                :rowspan="itemRowspan(item)"
                class="table-actions"
              >
                <button
                  v-if="item._destroy"
                  :disabled="actionsDisabled()"
                  type="button"
                  class="btn btn-sm btn-primary"
                  @click.prevent="restoreItem(itemIdx)"
                >
                  Restore
                </button>
                <template v-else>
                  <button
                    :disabled="actionsDisabled()"
                    type="button"
                    class="btn btn-sm btn-primary"
                    @click.prevent="showEditItem(itemIdx)"
                  >
                    <span class="glyphicons edit" />
                  </button>
                  <button
                    type="button"
                    :disabled="actionsDisabled()"
                    class="btn btn-sm btn-danger"
                    @click.prevent="deleteItem(itemIdx)"
                  >
                    <span class="glyphicons delete" />
                  </button>
                </template>
              </td>
            </tr>
            <tr
              v-if="isRoundTripTravel(item)"
              :key="`cost-item-trip-data-${itemIdx}`"
            >
              <td />
              <td />
              <td class="text-right text-secondary"><span class="glyphicons return-trip" /></td>
              <td>{{ item.inboundFrom }}</td>
              <td>{{ item.inboundTo }}</td>
              <td>{{ fDateTime(item.inboundDeparture) }}</td>
              <td>{{ fDateTime(item.inboundArrival) }}</td>
            </tr>
            <tr
              v-if="itemIdx === editItemId && editItem != null"
              :key="`cost-item-data-edit-${itemIdx}`"
            >
              <td colspan="10">
                <div class="mx-3 my-2 rapid-form edit-form">
                  <div class="form-row">
                    <div class="col-md-8">
                      <div class="form-row">
                        <div class="form-group col-6">
                          <label>{{ $t('statement_of_costs_table.item_row.cost_type') }}</label>
                          <select
                            v-model="editItem.costTypeId"
                            name="cost_type_id"
                            :class="{ 'custom-select custom-select-sm': true, 'is-invalid': fieldInvalid('costTypeId') }"
                          >
                            <option
                              v-for="(costType, costTypeIdx) of costTypes"
                              :key="`edit-item-cost-type-${costTypeIdx}`"
                              :value="costType.id"
                            >
                              {{ costTypeExtendedTitle(costType) }}
                            </option>
                          </select>
                          <div
                            v-if="fieldInvalid('costTypeId')"
                            class="invalid-feedback"
                          >
                            {{ fieldErrorMessage('costTypeId') }}
                          </div>
                        </div>
                        <div
                          v-if="showTransportMode"
                          class="form-group col-6"
                        >
                          <label>{{ $t('statement_of_costs_table.item_row.transport_mode') }}</label>
                          <select
                            v-model="editItem.transportModeId"
                            name="transport_mode_id"
                            :class="{ 'custom-select custom-select-sm': true, 'is-invalid': fieldInvalid('transportModeId') }"
                          >
                            <option
                              v-for="(transportMode, transportModeIdx) of transportModes"
                              :key="`edit-item-transport-mode-${transportModeIdx}`"
                              :value="transportMode.id"
                            >
                              {{ transportModeTitle(transportMode) }}
                            </option>
                          </select>
                          <div
                            v-if="fieldInvalid('transportModeId')"
                            class="invalid-feedback"
                          >
                            {{ fieldErrorMessage('transportModeId') }}
                          </div>
                        </div>
                      </div>
                      <template v-if="hasCostType">
                        <div
                          v-if="showTransportMode"
                          class="form-row"
                        >
                          <div class="form-group col">
                            <div :class="['custom-control custom-radio custom-control-inline', { 'is-invalid': fieldInvalid('tripTypeId') }]">
                              <input
                                id="customRadioInline1"
                                v-model="editItem.tripTypeId"
                                type="radio"
                                name="customRadioInline1"
                                class="custom-control-input"
                                :value="1"
                              >
                              <label
                                class="custom-control-label"
                                for="customRadioInline1"
                              >
                                {{ $t('statement_of_costs_table.item_row.one_way') }}
                              </label>
                            </div>
                            <div :class="['custom-control custom-radio custom-control-inline', { 'is-invalid': fieldInvalid('tripTypeId') }]">
                              <input
                                id="customRadioInline2"
                                v-model="editItem.tripTypeId"
                                type="radio"
                                name="customRadioInline1"
                                class="custom-control-input"
                                :value="2"
                              >
                              <label
                                class="custom-control-label"
                                for="customRadioInline2"
                              >
                                {{ $t('statement_of_costs_table.item_row.round_trip') }}
                              </label>
                            </div>
                            <div
                              v-if="fieldInvalid('tripTypeId')"
                              class="invalid-feedback"
                            >
                              {{ fieldErrorMessage('tripTypeId') }}
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="showFromTo"
                          class="form-row"
                        >
                          <div class="form-group col-6">
                            <label>{{ $t('statement_of_costs_table.item_row.from') }}</label>
                            <input
                              v-model="editItem.from"
                              name="from"
                              type="text"
                              :class="{ 'form-control form-control-sm' : true, 'is-invalid': fieldInvalid('from') }"
                            >
                            <div
                              v-if="fieldInvalid('from')"
                              class="invalid-feedback"
                            >
                              {{ fieldErrorMessage('from') }}
                            </div>
                          </div>
                          <div class="form-group col-6">
                            <label>{{ $t('statement_of_costs_table.item_row.to') }}</label>
                            <input
                              v-model="editItem.to"
                              name="to"
                              type="text"
                              :class="{ 'form-control form-control-sm' : true, 'is-invalid': fieldInvalid('to') }"
                            >
                            <div
                              v-if="fieldInvalid('to')"
                              class="invalid-feedback"
                            >
                              {{ fieldErrorMessage('to') }}
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="showDepartureArrival"
                          class="form-row"
                        >
                          <div class="form-group col-md-6 ">
                            <label>{{ $t('statement_of_costs_table.item_row.departure_date_time') }}</label>
                            <date-picker
                              v-model="editItem.departure"
                              idx="dp1"
                              :small="true"
                              :invalid="dateFieldInvalid('departure')"
                              :min-date="minTravelDateDeparture"
                              data-date-ref="item-departure-date"
                            />
                            <div
                              v-if="dateFieldInvalid('departure')"
                              class="invalid-feedback"
                            >
                              {{ dateFieldErrorMessage('departure') }}
                            </div>
                          </div>
                          <div class="form-group col-md-6 ">
                            <label>{{ $t('statement_of_costs_table.item_row.arrival_date_time') }}</label>
                            <date-picker
                              v-model="editItem.arrival"
                              idx="dp2"
                              :small="true"
                              :invalid="dateFieldInvalid('arrival')"
                              :min-date="minTravelDateArrival"
                              data-date-sync-ref="item-departure-date"
                            />
                            <div
                              v-if="dateFieldInvalid('arrival')"
                              class="invalid-feedback"
                            >
                              {{ dateFieldErrorMessage('arrival') }}
                            </div>
                          </div>
                        </div>
                        <template v-if="showInboundTrip">
                          <div class="form-row">
                            <div class="form-group col-6">
                              <label>{{ $t('statement_of_costs_table.item_row.from') }}</label>
                              <input
                                v-model="editItem.inboundFrom"
                                name="inboundFrom"
                                type="text"
                                :class="{ 'form-control form-control-sm' : true, 'is-invalid': fieldInvalid('inboundFrom') }"
                              >
                              <div
                                v-if="fieldInvalid('inboundFrom')"
                                class="invalid-feedback"
                              >
                                {{ fieldErrorMessage('inboundFrom') }}
                              </div>
                            </div>
                            <div class="form-group col-6">
                              <label>{{ $t('statement_of_costs_table.item_row.to') }}</label>
                              <input
                                v-model="editItem.inboundTo"
                                name="inboundTo"
                                type="text"
                                :class="{ 'form-control form-control-sm' : true, 'is-invalid': fieldInvalid('inboundTo') }"
                              >
                              <div
                                v-if="fieldInvalid('inboundTo')"
                                class="invalid-feedback"
                              >
                                {{ fieldErrorMessage('inboundTo') }}
                              </div>
                            </div>
                          </div>
                          <div class="form-row">
                            <div class="form-group col-md-6 ">
                              <label>{{ $t('statement_of_costs_table.item_row.departure_date_time') }}</label>
                              <date-picker
                                v-model="editItem.inboundDeparture"
                                idx="inboundDp1"
                                :small="true"
                                :invalid="dateFieldInvalid('inboundDeparture')"
                                :min-date="minTravelDateInboundDeparture"
                                data-date-ref="item-inbound-departure-date"
                              />
                              <div
                                v-if="dateFieldInvalid('inboundDeparture')"
                                class="invalid-feedback"
                              >
                                {{ dateFieldErrorMessage('inboundDeparture') }}
                              </div>
                            </div>
                            <div class="form-group col-md-6 ">
                              <label>{{ $t('statement_of_costs_table.item_row.arrival_date_time') }}</label>
                              <date-picker
                                v-model="editItem.inboundArrival"
                                idx="inboundDp2"
                                :small="true"
                                :invalid="dateFieldInvalid('inboundArrival')"
                                :min-date="minTravelDateInboundArrival"
                                data-date-sync-ref="item-inbound-departure-date"
                              />
                              <div
                                v-if="dateFieldInvalid('inboundArrival')"
                                class="invalid-feedback"
                              >
                                {{ dateFieldErrorMessage('inboundArrival') }}
                              </div>
                            </div>
                          </div>
                        </template>
                        <div
                          v-if="showArrivalDeparture"
                          class="form-row"
                        >
                          <div class="form-group col-md-6 ">
                            <label>{{ $t('statement_of_costs_table.item_row.arrival_date_time') }}</label>
                            <date-picker
                              v-model="editItem.arrival"
                              idx="dp2"
                              :small="true"
                              :invalid="dateFieldInvalid('arrival')"
                              data-date-ref="item-accomodation-arrival-date"
                            />
                            <div
                              v-if="dateFieldInvalid('arrival')"
                              class="invalid-feedback"
                            >
                              {{ dateFieldErrorMessage('arrival') }}
                            </div>
                          </div>
                          <div class="form-group col-md-6 ">
                            <label>{{ $t('statement_of_costs_table.item_row.departure_date_time') }}</label>
                            <date-picker
                              v-model="editItem.departure"
                              idx="dp1"
                              :small="true"
                              :invalid="dateFieldInvalid('departure')"
                              data-date-sync-ref="item-accomodation-arrival-date"
                            />
                            <div
                              v-if="dateFieldInvalid('departure')"
                              class="invalid-feedback"
                            >
                              {{ dateFieldErrorMessage('departure') }}
                            </div>
                          </div>
                        </div>
                        <template v-if="isPrivateCar">
                          <div class="form-row">
                            <div class="col-6">
                              <div class="form-row">
                                <div class="form-group col-12">
                                  <label>{{ $t('statement_of_costs_table.item_row.private_car.total_distance_driven') }}</label>
                                  <div :class="['input-group input-group-sm w-50 pr-1', { 'is-invalid': fieldInvalid('privateCarDistance') }]">
                                    <input
                                      v-model="editItem.privateCarDistance"
                                      name="private_car_distance"
                                      type="text"
                                      class="form-control"
                                    >
                                    <div class="input-group-append">
                                      <span class="input-group-text">km</span>
                                    </div>
                                  </div>
                                  <div
                                    v-if="fieldInvalid('privateCarDistance')"
                                    class="invalid-feedback"
                                  >
                                    {{ fieldErrorMessage('privateCarDistance') }}
                                  </div>
                                </div>
                                <div class="form-group col-6">
                                  <label>{{ $t('statement_of_costs_table.item_row.amount') }}</label>
                                  <div class="input-group input-group-sm">
                                    <input
                                      :value="fToFixed(editItem.privateCarAmount)"
                                      name="private_car_amount"
                                      type="text"
                                      class="form-control"
                                      readonly
                                    >
                                    <div class="input-group-append">
                                      <span class="input-group-text">EUR</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="form-group col-6">
                              <label>{{ $t('statement_of_costs_table.item_row.private_car.map') }}</label>
                              <div :class="{ 'is-invalid': fieldInvalid('files') }">
                                <div
                                  v-if="editItem.files.length > 0"
                                  class="item-files"
                                >
                                  <div
                                    v-for="(file, fileIdx) of editItem.files"
                                    :key="`edit-item-transport-file-${fileIdx}`"
                                    class="p-1 d-flex"
                                  >
                                    <a
                                      :href="file.url"
                                      target="_blank"
                                      :class="['flex-fill', { 'for-destruction text-danger': file._destroy }]"
                                    >
                                      {{ file.filename }}
                                    </a>
                                    <button
                                      v-if="file._destroy"
                                      class="btn btn-sm btn-primary"
                                      @click.prevent="restoreItemFile(fileIdx)"
                                    >
                                      Restore
                                    </button>
                                    <button
                                      v-else
                                      class="btn btn-sm btn-danger"
                                      @click.prevent="removeItemFile(fileIdx)"
                                    >
                                      <span class="glyphicons delete" />
                                    </button>
                                  </div>
                                </div>
                                <span v-else>{{ $t('statement_of_costs_table.item_row.no_file_uploaded') }}</span>
                                <upload-button
                                  :upload-url="uploadUrl"
                                  :disabled-message="uploadDisabledMessage"
                                  input-id="file_upload"
                                  @upload-progress="onUploadProgress"
                                  @uploaded="onUploaded"
                                  @upload-error="onUploadError"
                                />
                              </div>
                              <div
                                v-if="fieldInvalid('files')"
                                class="invalid-feedback"
                              >
                                {{ fieldErrorMessage('files') }}
                              </div>
                              <div
                                v-if="uploadFileError"
                                class="invalid-feedback d-block"
                              >
                                {{ uploadFileError }}
                              </div>
                              <progress-indicator
                                v-if="isUploading"
                                v-bind="uploadProgressProps"
                              />
                            </div>
                          </div>
                          <div class="form-row">
                            <p
                              class="col-6 form-text text-muted small-text"
                              v-html="$t('statement_of_costs_table.item_row.private_car.cost_info')"
                            />
                            <p class="col-6 form-text text-muted small-text">
                              {{ $t('statement_of_costs_table.item_row.private_car.map_info') }}
                            </p>
                          </div>
                          <div class="form-row mt-4 mb-4">
                            <div :class="{ 'is-invalid': fieldInvalid('privateCarClaimTypeId') }">
                              <div class="custom-control custom-radio">
                                <input
                                  id="privateCarClaimType1"
                                  v-model="editItem.privateCarClaimTypeId"
                                  :value="privateCarClaimType.onlyPassenger"
                                  type="radio"
                                  name="private_car_claim_type_id"
                                  class="custom-control-input"
                                >
                                <label
                                  class="custom-control-label"
                                  for="privateCarClaimType1"
                                >
                                  {{ $t('statement_of_costs_table.item_row.private_car.claim_only_participant') }}
                                </label>
                              </div>
                              <div class="custom-control custom-radio">
                                <input
                                  id="privateCarClaimType2"
                                  v-model="editItem.privateCarClaimTypeId"
                                  :value="privateCarClaimType.withOtherPassengers"
                                  type="radio"
                                  name="private_car_claim_type_id"
                                  class="custom-control-input"
                                >
                                <label
                                  class="custom-control-label"
                                  for="privateCarClaimType2"
                                >
                                  {{ $t('statement_of_costs_table.item_row.private_car.claim_other_participants') }}
                                </label>
                              </div>
                            </div>
                            <div
                              v-if="fieldInvalid('privateCarClaimTypeId')"
                              class="invalid-feedback"
                            >
                              {{ fieldErrorMessage('privateCarClaimTypeId') }}
                            </div>

                            <div class="pl-4 form-group col-sm">
                              <input
                                v-model="editItem.privateCarClaimOtherPassengers"
                                type="text"
                                :class="['form-control form-control-sm', { 'is-invalid': fieldInvalid('privateCarClaimOtherPassengers')}]"
                                name="other_passengers"
                              >
                              <small
                                id="other_passengers_help"
                                class="form-text text-muted"
                              >
                                {{ $t('statement_of_costs_table.item_row.private_car.provide_other_participants') }}
                              </small>
                              <div
                                v-if="fieldInvalid('privateCarClaimOtherPassengers')"
                                class="invalid-feedback"
                              >
                                {{ fieldErrorMessage('privateCarClaimOtherPassengers') }}
                              </div>
                            </div>
                          </div>
                          <div class="form-row">
                            <p class="col-12 form-text font-weight-bold">
                              {{ $t('statement_of_costs_table.item_row.private_car.accidents_info') }}
                            </p>
                          </div>
                        </template>
                        <div
                          v-else
                          class="form-row"
                        >
                          <div class="form-group col-3">
                            <label>{{ $t('statement_of_costs_table.item_row.amount') }}</label>
                            <div :class="['input-group input-group-sm', { 'is-invalid': fieldInvalid('amount') }]">
                              <input
                                v-model="editItem.amount"
                                name="amount"
                                type="text"
                                class="form-control"
                              >
                              <div class="input-group-append">
                                <span class="input-group-text">EUR</span>
                              </div>
                            </div>
                            <div
                              v-if="fieldInvalid('amount')"
                              class="invalid-feedback"
                            >
                              {{ fieldErrorMessage('amount') }}
                            </div>
                          </div>
                          <div class="form-group offset-3 col-6">
                            <label>{{ $t('statement_of_costs_table.item_row.invoice_evidence') }}</label>
                            <div :class="{ 'is-invalid': fieldInvalid('files') }">
                              <div
                                v-if="editItem.files.length > 0"
                                class="item-files"
                              >
                                <div
                                  v-for="(file, fileIdx) of editItem.files"
                                  :key="`edit-item-file-${fileIdx}`"
                                  class="p-1 d-flex"
                                >
                                  <a
                                    :href="file.url"
                                    target="_blank"
                                    :class="['flex-fill', { 'for-destruction text-danger': file._destroy }]"
                                  >
                                    {{ file.filename }}
                                  </a>
                                  <button
                                    class="btn btn-sm btn-danger"
                                    @click.prevent="removeItemFile(fileIdx)"
                                  >
                                    <span class="glyphicons delete" />
                                  </button>
                                </div>
                              </div>
                              <span v-else>{{ $t('statement_of_costs_table.item_row.no_file_uploaded') }}</span>
                              <upload-button
                                :upload-url="uploadUrl"
                                :disabled-message="uploadDisabledMessage"
                                input-id="file_upload"
                                @upload-progress="onUploadProgress"
                                @uploaded="onUploaded"
                                @upload-error="onUploadError"
                              />
                            </div>
                            <div
                              v-if="fieldInvalid('files')"
                              class="invalid-feedback"
                            >
                              {{ fieldErrorMessage('files') }}
                            </div>
                            <div
                              v-if="uploadFileError"
                              class="invalid-feedback d-block"
                            >
                              {{ uploadFileError }}
                            </div>
                            <progress-indicator
                              v-if="isUploading"
                              v-bind="uploadProgressProps"
                            />
                          </div>
                        </div>
                      </template>
                    </div>
                    <div class="col-md-4">
                      <div
                        v-if="showTransportMode"
                        class="alert alert-info"
                      >
                        {{ $t('statement_of_costs_table.transport_mode_message') }}
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    class="btn btn-sm btn-success"
                    @click.prevent="updateItem"
                  >
                    {{ $t('statement_of_costs_table.item_row.save') }}
                  </button>
                  <button
                    type="button"
                    class="btn btn-sm btn-default"
                    @click.prevent="cancelEdit"
                  >
                    {{ $t('statement_of_costs_table.item_row.cancel') }}
                  </button>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
  import UploadButton from './upload-button.vue'
  import DatePicker from './date-picker.vue'
  import ProgressIndicator from './progress-indicator.vue'

  import { isNonEmptyString } from '../js-utils'

  import validate from 'validate.js'
  import moment from 'moment'

  const MAX_EVIDENCE_FILES = 4

  interface DateValue {
    date: string;
    time: string;
    value?: Date;
    moment?: moment.Moment;
  }

  interface CostItemFile {
    id: string;
    url: string;
    filename: string;
    _saved: number;
    _destroy: number;
  }

  interface CostItem {
    id: string | null;
    nr: number | null;
    _destroy: boolean | number;
    costTypeId: number | null;
    transportModeId: number | null;
    tripTypeId: number;
    date?: string | null;
    time?: string | null;
    amount: string | null;
    privateCarAmount: number | null;
    from: string | null;
    to: string | null;
    arrival: DateValue;
    departure: DateValue;
    files: CostItemFile[];
    inboundArrival: DateValue;
    inboundDeparture: DateValue;
    inboundFrom: string | null;
    inboundTo: string | null;
    privateCarDistance: string | null;
    privateCarClaimTypeId: number | null;
    privateCarClaimOtherPassengers: string;
  }

  enum CostType {
    transport = 1,
    accommodation = 2,
    meals = 3,
  }

  enum TransportMode {
    plane = 1,
    train = 2,
    bus = 3,
    ship = 4,
    privateCar = 5,
    other = 6,
  }

  interface CostTypeObj {
    id: CostType | null;
    i18nKey: string | null;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const CostTypes: CostTypeObj[] = [
    { id: null, i18nKey: null },
    { id: CostType.transport, i18nKey: 'transport' },
    { id: CostType.accommodation, i18nKey: 'accommodation' },
    { id: CostType.meals, i18nKey: 'meals' },
  ]

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const CostTypeIds = CostTypes.map(x => x.id)

  interface TransportModeObj {
    id: TransportMode | null;
    i18nKey: string | null;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const TransportModes: TransportModeObj[] = [
    { id: null, i18nKey: null },
    { id: TransportMode.plane, i18nKey: 'plane' },
    { id: TransportMode.train, i18nKey: 'train' },
    { id: TransportMode.bus, i18nKey: 'bus' },
    { id: TransportMode.ship, i18nKey: 'ship' },
    { id: TransportMode.privateCar, i18nKey: 'private_car' },
    { id: TransportMode.other, i18nKey: 'other' },
  ]

// eslint-disable-next-line @typescript-eslint/naming-convention
  const TransportModeIds = TransportModes.map(x => x.id)

  enum TripType {
    oneWay = 1,
    roundTrip = 2,
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const TripTypes = [
    { id: TripType.oneWay, title: 'One way' },
    { id: TripType.roundTrip, title: 'Round trip' },
  ]

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const TripTypeIds = TripTypes.map(x => x.id)

  enum PrivateCarClaimType {
    onlyPassenger = 1,
    withOtherPassengers = 2,
  }

  validate.validators.beforeDate = function(value: any, options: any, _key: any, _attributes: any): string | undefined {
    if (validate.isDate(value) && validate.isDate(options.before) && moment(value).diff(options.before, 'minutes') > 0) {
      return options.message || `must be before ${options.before}`
    }
  }
  validate.validators.validMoment = function(value: any, options: any, _key: any, _attributes: any): string | undefined {
    if (value && !value.isValid()) {
      return options.message || `is not a valid date time`
    }
  }
  validate.validators.maxFiles = function(value: any, options: any, _key: any, _attributes: any): string | undefined {
    let numFiles = 0
    if (Array.isArray(value)) {
      for (const file of value) {
        if (file._destroy != 1) {
          numFiles += 1
        }
      }
    }
    const minFiles = options && typeof options.minFiles === 'number' ? parseInt(options.minFiles) : 0
    if (numFiles > MAX_EVIDENCE_FILES) {
      return `Maximum of ${MAX_EVIDENCE_FILES} are allowed`
    } else if (minFiles > 0 && numFiles < minFiles) {
      if (minFiles == 1) {
        return 'upload at least one file'
      } else {
        return `upload at least ${minFiles} files`
      }
    }
  }

  export default defineComponent({
    components: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      DatePicker, ProgressIndicator, UploadButton
    },

    props: {
      uploadUrl: { default: null, type: String },
      itemsJsonElRef: { default: null, type: String }, // replace by ref prop?
    },

    data () {
      return {
        editItemId: null as number | null,
        editItem: null as CostItem | null,
        editItemValidationErrors: null as Record<string, string[]> | null,
        items: [] as CostItem[],
        costTypes: CostTypes,
        transportModes: TransportModes,
        tripTypes: TripTypes,
        privateCarClaimType: PrivateCarClaimType,
        isUploading: false,
        uploadProgressProps: {
          progress: 0,
          message: null as string | null
        },
        uploadFileError: null as string | null,
        allDetailsVisible: false,
        itemsJsonEl: null as HTMLInputElement | null,
      }
    },
    computed: {
      hasCostType (): boolean {
        return typeof this.editItem?.costTypeId === 'number' && this.editItem.costTypeId > 0
      },

      showTransportMode (): boolean {
        return this.editItem?.costTypeId === CostType.transport
      },

      showFromTo (): boolean {
        return this.editItem?.costTypeId === CostType.transport
      },

      showArrivalDeparture (): boolean {
        return this.editItem?.costTypeId === CostType.accommodation
      },

      showDepartureArrival (): boolean {
        return this.editItem?.costTypeId === CostType.transport
      },

      showInboundTrip (): boolean {
        return this.editItem?.costTypeId === CostType.transport &&
          this.editItem.tripTypeId === TripType.roundTrip
      },

      isPrivateCar (): boolean {
        return this.editItem?.costTypeId === CostType.transport &&
          this.editItem.transportModeId === TransportMode.privateCar
      },

      uploadDisabledMessage (): string | null {
        if (this.editItem && this.editItem.files.length >= MAX_EVIDENCE_FILES) {
          return `Maximum of ${MAX_EVIDENCE_FILES} files allowed per item`
        }
        return null
      },

      minTravelDateDeparture (): string | null {
        for (let i = 0; i < this.items.length; i++) {
          const item = this.items[i]
          if (item.costTypeId === CostType.transport) {
            if (this.editItemId === i) {
              // first transport item can have any date
              return null
            }
            // remaining transport items can't have date earlier than the first date
            return item.departure.date
          }
        }
        return null
      },

      minTravelDateArrival (): string | null {
        if (this.editItem) {
          return this.editItem.departure.date || this.minTravelDateDeparture
        }
        return null
      },

      minTravelDateInboundDeparture (): string | null {
        if (this.editItem) {
          return this.editItem.arrival.date || this.minTravelDateArrival
        }
        return null
      },

      minTravelDateInboundArrival (): string | null {
        if (this.editItem) {
          return this.editItem.inboundArrival.date || this.minTravelDateInboundDeparture
        }
        return null
      },

      isEditing(): boolean {
        return this.editItemId !== null
      }
    },

    watch: {
      'editItem.privateCarDistance': function () {
        this.updatePrivateCarAmount()
      },
      'editItem.tripTypeId': function () {
        this.updatePrivateCarAmount()
      }
    },

    mounted () {
      if (isNonEmptyString(this.itemsJsonElRef)) {
        const el = document.querySelector(`input[ref="${this.itemsJsonElRef}"]`)
        if (el instanceof HTMLInputElement) {
          this.itemsJsonEl = el
        }
      }

      if (this.itemsJsonEl) {
        const initialItems = JSON.parse(this.itemsJsonEl.value)
        if (Array.isArray(initialItems)) {
          for (const item of initialItems) {
            this.items.push({
              id: item.id,
              nr: null,
              _destroy: item._destroy,
              costTypeId: item.cost_type_id,
              transportModeId: item.transport_mode_id,
              from: item.from,
              to: item.to,
              arrival: {
                date: item.arrival.date,
                time: item.arrival.time
              },
              departure: {
                date: item.departure.date,
                time: item.departure.time,
              },
              inboundFrom: item.inbound_from,
              inboundTo: item.inbound_to,
              inboundDeparture: {
                date: item.inbound_departure.date,
                time: item.inbound_departure.time
              },
              inboundArrival: {
                date: item.inbound_arrival.date,
                time: item.inbound_arrival.time
              },
              privateCarDistance: item.private_car_distance,
              privateCarAmount: item.private_car_amount,
              privateCarClaimTypeId: item.private_car_claim_type_id,
              privateCarClaimOtherPassengers: item.private_car_claim_other_passengers,
              tripTypeId: item.trip_type_id,
              amount: item.amount,
              files: item.files
            })
          }
        }
      }

      for (let i = this.items.length; i < 5; i++) {
        this.makeNewItem()
      }
      this.renumberItems()
      this.saveItemsJson()
    },
    methods: {

      fieldInvalid: function (fieldName: string): boolean {
        return this.editItemValidationErrors != null && this.editItemValidationErrors[fieldName] && this.editItemValidationErrors[fieldName].length > 0
      },
      fieldErrorMessage: function (fieldName: string): string | null {
        if (this.fieldInvalid(fieldName)) {
          return (this.editItemValidationErrors != null && this.editItemValidationErrors[fieldName][0]) || null
        }
        return null
      },

      dateFieldInvalid: function (fieldName: string): boolean {
        return this.fieldInvalid(`${fieldName}.moment`) || this.fieldInvalid(`${fieldName}.date`) || this.fieldInvalid(`${fieldName}.time`) || this.fieldInvalid(`${fieldName}.value`)
      },

      dateFieldErrorMessage: function (fieldName: string): string | null {
        for (const vField of [`${fieldName}.moment`, `${fieldName}.date`, `${fieldName}.time`, `${fieldName}.value`]) {
          if (this.fieldInvalid(vField)) {
            return this.fieldErrorMessage(vField)
          }
        }
        return null
      },

      makeNewItem (): void {
        if (this.items.length >= 20) {
          return
        }

        this.items.push({
          id: null,
          _destroy: 0,
          nr: null,
          costTypeId: null,
          transportModeId: null,
          from: null,
          to: null,
          arrival: {
            date: '',
            time: ''
          },
          departure: {
            date: '',
            time: ''
          },
          inboundFrom: null,
          inboundTo: null,
          inboundDeparture: {
            date: '',
            time: ''
          },
          inboundArrival: {
            date: '',
            time: ''
          },
          privateCarDistance: null,
          privateCarAmount: null,
          privateCarClaimTypeId: null,
          privateCarClaimOtherPassengers: '',
          tripTypeId: TripType.oneWay,
          amount: null,
          files: []
        })
      },

      renumberItems (): void {
        for (let i = 0; i < this.items.length; i++) {
          this.items[i].nr = i + 1
        }
      },

      addItem (): void {
        this.makeNewItem()
        this.renumberItems()
        this.saveItemsJson()
      },

      showEditItem (itemIdx: number): void {
        this.editItemValidationErrors = null

        if (this.editItemId === itemIdx) {
          this.editItemId = null
          this.editItem = null
        } else {
          this.editItemId = itemIdx
          this.editItem = JSON.parse(JSON.stringify(this.items[itemIdx]))
          if (this.editItem && this.editItem.tripTypeId == null) {
            this.editItem.tripTypeId = TripType.oneWay
          }
        }
      },

      updateItem (): void {
        if (this.editItemId !== null && this.editItem) {
          // fix decimal numbers
          if (typeof this.editItem.privateCarDistance === 'string') {
            this.editItem.privateCarDistance = this.editItem.privateCarDistance.replace(',', '.')
          }
          if (typeof this.editItem.amount === 'string') {
            this.editItem.amount = this.editItem.amount.replace(',', '.')
          }

          if (this.validateItem(this.editItem)) {
            this.items.splice(this.editItemId, 1, JSON.parse(JSON.stringify(this.editItem)))
            this.renumberItems()
            this.editItemId = null
            this.editItem = null

            this.saveItemsJson()
          }
        }
      },

      cancelEdit (): void {
        this.editItemValidationErrors = null
        this.editItemId = null
      },

      deleteItem (itemIdx: number): void {
        if (confirm('Delete this row?')) {
          if (this.items[itemIdx].id) {
            // item has id set, mark item for deletion
            this.items[itemIdx]._destroy = 1
          } else {
            this.items.splice(itemIdx, 1)
            this.renumberItems()
          }
          this.saveItemsJson()
        }
      },

      restoreItem (itemIdx: number): void {
        this.items[itemIdx]._destroy = 0
        this.saveItemsJson()
      },

      actionsDisabled (): boolean {
        return this.editItemId !== null
      },

      validateItem (item: CostItem): boolean {
        const itemData = JSON.parse(JSON.stringify(item))
        itemData.arrival = this.getDateValue(itemData.arrival)
        itemData.departure = this.getDateValue(itemData.departure)
        itemData.inboundArrival = this.getDateValue(itemData.inboundArrival)
        itemData.inboundDeparture = this.getDateValue(itemData.inboundDeparture)

        const aTripType = parseInt(itemData.tripTypeId)
        if (!isNaN(aTripType)) {
          itemData.tripTypeId = aTripType
        }

        const constraints: Record<string, any> = {
          costTypeId: {
            presence: { allowEmpty: false },
            inclusion: {
              within: CostTypeIds
            }
          }
        }

        if (itemData.costTypeId == CostType.transport) {
          constraints.transportModeId = {
            presence: { allowEmpty: false },
            inclusion: {
              within: TransportModeIds
            }
          }
          constraints.from = {
            presence: { allowEmpty: false }
          }
          constraints.to = {
            presence: { allowEmpty: false }
          }
          constraints['arrival.date'] = {
            presence: { allowEmpty: false }
          }
          constraints['arrival.time'] = {
            presence: { allowEmpty: false }
          }
          constraints['arrival.value'] = {
            presence: { allowEmpty: false },
          }
          constraints['arrival.moment'] = {
            validMoment: true
          }

          constraints['departure.moment'] = {
            validMoment: true
          }

          constraints['departure.date'] = {
            presence: { allowEmpty: false }
          }
          constraints['departure.time'] = {
            presence: { allowEmpty: false }
          }

          constraints['departure.value'] = {
            presence: { allowEmpty: false },
            beforeDate: {
              before: itemData.arrival && itemData.arrival.value,
              message: 'must be before arrival date'
            }
          }
          constraints['tripTypeId'] = {
            presence: { allowEmpty: false },
            inclusion: { within: TripTypeIds },
          }
          if (itemData.transportModeId === TransportMode.privateCar) {
            constraints['privateCarDistance'] = {
              presence: { allowEmpty: false },
              numericality: {
                greaterThan: 0,
                lessThanOrEqualTo: 600
              }
            }
            constraints['privateCarClaimTypeId'] = {
              presence: {
                allowEmpty: false,
                message: '^You must select one of the options'
              },
              inclusion: {
                within: [PrivateCarClaimType.onlyPassenger, PrivateCarClaimType.withOtherPassengers],
                message: '^You must select one of the options'
              }
            }
            if (itemData.privateCarClaimTypeId == PrivateCarClaimType.withOtherPassengers) {
              constraints['privateCarClaimOtherPassengers'] = {
                presence: {
                  allowEmpty: false,
                  message: '^Must enter names of other participants'
                }
              }
            }
          }
          if (itemData.tripTypeId === TripType.roundTrip) {
            constraints.inboundFrom = {
              presence: { allowEmpty: false }
            }
            constraints.inboundTo = {
              presence: { allowEmpty: false }
            }
            constraints['inboundArrival.date'] = {
              presence: { allowEmpty: false }
            }
            constraints['inboundArrival.time'] = {
              presence: { allowEmpty: false }
            }
            constraints['inboundArrival.value'] = {
              presence: { allowEmpty: false },
            }
            constraints['inboundArrival.moment'] = {
              validMoment: true
            }

            constraints['inboundDeparture.moment'] = {
              validMoment: true
            }

            constraints['inboundDeparture.date'] = {
              presence: { allowEmpty: false }
            }
            constraints['inboundDeparture.time'] = {
              presence: { allowEmpty: false }
            }

            constraints['inboundDeparture.value'] = {
              presence: { allowEmpty: false },
              beforeDate: {
                before: itemData.inboundArrival && itemData.inboundArrival.value,
                message: 'must be before arrival date'
              }
            }
          }
        }

        if (itemData.costTypeId == CostType.accommodation) {
          constraints['arrival.date'] = {
            presence: { allowEmpty: false }
          }
          constraints['arrival.time'] = {
            presence: { allowEmpty: false }
          }
          constraints['arrival.value'] = {
            presence: { allowEmpty: false },
          }
          constraints['arrival.moment'] = {
            validMoment: true
          }

          constraints['departure.moment'] = {
            validMoment: true
          }

          constraints['departure.date'] = {
            presence: { allowEmpty: false }
          }
          constraints['departure.time'] = {
            presence: { allowEmpty: false }
          }

          constraints['arrival.value'] = {
            presence: { allowEmpty: false },
            beforeDate: {
              before: itemData.departure && itemData.departure.value,
              message: 'must be before departure date'
            }
          }
        }

        let requireFiles = true
        if (itemData.costTypeId == CostType.transport) {
          // only the first ransport row requires amount/files, for other transport rows thats optional
          for (let i = 0; i < this.items.length; i++) {
            // check only the previous items
            if (this.editItemId == null || i >= this.editItemId) {
              break
            }

            const item = this.items[i]
            if (item.costTypeId == CostType.transport) {
              requireFiles = false
              break
            }
          }
        }

        if (itemData.costTypeId == CostType.transport) {
          if (itemData.transportModeId !== TransportMode.privateCar) {
            if (requireFiles) {
              constraints['amount'] = {
                presence: { allowEmpty: false },
                numericality: {
                  greaterThan: 0
                }
              }
            }
          }
        } else {
          constraints['amount'] = {
            presence: { allowEmpty: false },
            numericality: {
              greaterThan: 0
            }
          }
        }

        if (requireFiles) {
          // commont validations
          constraints['files'] = {
            maxFiles: { minFiles: requireFiles ? 1 : 0 },
          }
        }

        // if (typeof this.activityData.price === 'string') {
        //   this.activityData.price = this.activityData.price ? this.activityData.price.replace(',', '.') : null
        // }
        // const constraints = {
        //   name: {
        //     presence: { allowEmpty: false }
        //   },
        //   price: {
        //     numericality: {
        //       greaterThan: 0
        //     }
        //   }
        // }

        // const activityData = {
        //   name: this.activityData.name,
        //   nameFr: this.activityData.nameFr,
        //   price: this.activityData.price,
        //   description: this.activityData.description,
        //   descriptionFr: this.activityData.descriptionFr,
        //   allowNotes: this.activityData.allowNotes,
        //   showDescription: this.activityData.showDescription
        // }

        this.editItemValidationErrors = validate(itemData, constraints, {
          // format: 'detailed',
          prettify: function (value: string): string {
            switch(value) {
              case 'costTypeId':
                return 'Cost type'
              case 'transportModeId':
                return 'Transport mode'
              case 'departure.value':
              case 'departure.moment':
                return 'Departure date'
              case 'arrival.value':
              case 'arrival.moment':
                return 'Arrival date'
              case 'privateCarDistance':
                return 'Distance'
              case 'tripTypeId':
                return 'Trip type'
              case 'files':
                return 'Invoice/Evidence'
              default:
                return validate.prettify(value)
            }
          }
        })
        return !this.editItemValidationErrors
      },

      getDateValue (inputDate: DateValue): DateValue {
        const dv = JSON.parse(JSON.stringify(inputDate))
        dv.value = null
        dv.moment = null

        if (typeof dv.date === 'string' && dv.date.length > 0 && typeof dv.time === 'string' && dv.time.length > 0) {
          dv.moment = moment(`${dv.date} ${dv.time}`, 'DD/MM/YYYY HH:mm', true)
          if (dv.moment.isValid()) {
            dv.value = dv.moment.toDate()
            return dv
          }
        }
        return dv
      },

      onUploadProgress (isUploading: boolean, uploadProgress: number, uploadMessage: string): void {
        this.uploadFileError = null
        this.isUploading = isUploading
        this.uploadProgressProps.message = uploadMessage
        this.uploadProgressProps.progress = uploadProgress
      },

      onUploaded (data: any): void {
        if (data && data.file && this.editItem) {
          this.editItem.files.push({
            id: data.file.id,
            url: data.file.url,
            filename: data.file.filename,
            _saved: 0,
            _destroy: 0
          })
        }
      },

      onUploadError(error: string): void {
        this.isUploading = false
        this.uploadFileError = error
      },

      removeItemFile(fileIdx: number): void {
        this.uploadFileError = null
        if (this.editItem == null) {
          return
        }

        if (confirm('Delete file?')) {
          const f = this.editItem.files[fileIdx]
          if (f._saved) {
            f._destroy = 1
          } else {
            this.editItem.files.splice(fileIdx, 1)
          }
        }
      },

      restoreItemFile (fileIdx: number): void {
        if (this.editItem) {
          this.editItem.files[fileIdx]._destroy = 0
        }
      },

      saveItemsJson(): void {
        const storeItems = []
        for (const item of this.items) {
          const newItem = {
            id: item.id,
            _destroy: item._destroy,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            cost_type_id: item.costTypeId,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            transport_mode_id: item.transportModeId,
            from: item.from,
            to: item.to,
            arrival: {
              date: item.arrival.date,
              time: item.arrival.time
            },
            departure: {
              date: item.departure.date,
              time: item.departure.time,
            },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            inbound_from: item.inboundFrom,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            inbound_to: item.inboundTo,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            inbound_arrival: {
              date: item.inboundArrival.date,
              time: item.inboundArrival.time
            },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            inbound_departure: {
              date: item.inboundDeparture.date,
              time: item.inboundDeparture.time,
            },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            private_car_distance: item.privateCarDistance,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            private_car_amount: item.privateCarAmount,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            private_car_claim_type_id: item.privateCarClaimTypeId,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            private_car_claim_other_passengers: item.privateCarClaimOtherPassengers,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            trip_type_id: item.tripTypeId,
            amount: item.amount,
            files: [] as any[]
          }

          for (const file of item.files) {
            newItem.files.push({
              id: file.id,
              url: file.url,
              filename: file.filename,
              _saved: file._saved,
              _destroy: file._destroy
            })
          }

          storeItems.push(newItem)
        }

        if (this.itemsJsonEl) {
          this.itemsJsonEl.value = JSON.stringify(storeItems)
        }
      },

      updatePrivateCarAmount(): void {
        if (this.editItem == null) {
          return
        }

        let distance: string | number | null  = this.editItem.privateCarDistance

        if (typeof distance === 'string') {
          distance = parseFloat(distance)
          if (isNaN(distance)) {
            distance = null
          }
        }
        if (typeof distance === 'number') {
          if (distance > 1200) {
            distance = 1200
          }
          this.editItem.privateCarAmount = distance * 0.22
        } else {
          this.editItem.privateCarAmount = null
        }
      },

      showAllDetails (): void {
        this.allDetailsVisible = true
      },

      isRoundTripTravel (item: CostItem): boolean {
        return item != null && item.costTypeId === CostType.transport &&
          item.tripTypeId === TripType.roundTrip
      },

      itemRowspan (item: CostItem): number | null {
        if (this.isRoundTripTravel(item)) {
          return 2
        }
        return null
      },

      costTypeExtendedTitle(item: CostTypeObj): string {
        if (item.i18nKey == null) {
          return ''
        }
        return this.$t(`statement_of_costs_table.cost_type.${item.i18nKey}_extended`)
      },

      transportModeTitle(item: TransportModeObj): string {
        if (item.i18nKey == null) {
          return ''
        }
        return this.$t(`statement_of_costs_table.transport_mode.${item.i18nKey}`)
      },

      fCostType (item: CostItem): string {
        if (item.costTypeId) {
          const k = CostTypes[item.costTypeId].i18nKey
          return this.$t(`statement_of_costs_table.cost_type.${k}`)
        }
        return ''
      },

      fTransportMode (item: CostItem): string {
        if (item.costTypeId == CostType.transport && item.transportModeId) {
          const k = TransportModes[item.transportModeId].i18nKey
          return this.$t(`statement_of_costs_table.transport_mode.${k}`)
        }
        return ''
      },

      fDateTime (item: CostItem): string {
        const parts = []
        if (item) {
          if (isNonEmptyString(item.date)) {
            parts.push(item.date)
          }
          if (isNonEmptyString(item.time)) {
            parts.push(item.time)
          }
        }

        return parts.length > 0 ? parts.join(' ') : ''
      },

      fToFixed (value: number | string): string {
        if (typeof value === 'number') {
          return value.toFixed(2)
        }
        return value
      },

      fAmount (item: CostItem): string {
        let amount: string | number | null = item.amount
        if (item.costTypeId === CostType.transport && item.transportModeId === TransportMode.privateCar) {
          amount = item.privateCarAmount
        }

        let amountVal = amount as number | string
        if (typeof amount === 'string' && amount.length > 0) {
          amountVal = parseFloat(amount)
        }

        if (typeof amountVal === 'number' && !isNaN(amountVal)) {
          return amountVal.toFixed(2)
        }

        return ''
      },

      fJourneyFrom (item: CostItem): string {
        if (item.costTypeId === CostType.transport) {
          return item.from || ''
        }
        return ''
      },

      fJourneyTo (item: CostItem): string {
        if (item.costTypeId === CostType.transport) {
          return item.to || ''
        }
        return ''
      },

      fArrivalDate (item: CostItem): DateValue | null {
        if (item.costTypeId === CostType.transport || item.costTypeId === CostType.accommodation) {
          return item.arrival
        }

        return null
      },

      fDepartureDate (item: CostItem): DateValue | null {
        if (item.costTypeId === CostType.transport || item.costTypeId === CostType.accommodation) {
          return item.departure
        }
        return null
      }
    },
  })
</script>
