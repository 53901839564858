import $ from 'jquery'
import axios, { CancelTokenSource } from 'axios'
import type { Options as Select2Options, SearchOptions, IdTextPair, OptGroupData, OptionData } from 'select2'
import URI from 'urijs'
import Rails from '@rails/ujs'
import { isNonEmptyString, initializeDatePicker } from './js-utils'
import { initializeMultiUploadField, initializeUploadField } from './js-vue-utils'

const autocompleteCreateTag = function (params: SearchOptions): IdTextPair & { newTag: boolean } | null {
  const term = $.trim(params.term)

  if (term === '') {
    return null
  }

  return {
    id: term,
    text: `Other: ${term}`,
    newTag: true // add additional parameters
  }
}

const autocompleteResultsSorter = function (data: (OptGroupData | OptionData | IdTextPair & { newTag?: boolean })[]): (OptGroupData | OptionData | IdTextPair & { newTag?: boolean })[] {
  data.sort(function (a: any, b: any) {
    // move tags to the bottom of the results, leave
    // the rest as is
    if (a.newTag && b.newTag) {
      const aVal = a.id.toUpperCase()
      const bVal = b.id.toUpperCase()
      if (aVal < bVal) {
        return -1
      } else if (aVal > bVal) {
        return 1
      } else {
        return 0
      }
    } else if (a.newTag) {
      return 1
    } else if (b.newTag) {
      return -1
    } else {
      return 0
    }
  })
  return data
}

class Select2AxiosTransport {

  private cancelToken = null as CancelTokenSource | null
  private status = null as number | null
  private ts = ''

  public constructor (requestUrl: string, success: ((data: any) => undefined) | undefined, failure: (() => undefined) | undefined) {
    this.ts = new Date().getTime().toString()
    this.cancelToken = axios.CancelToken.source()
    axios
      .get(requestUrl, {
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'Accept': 'application/json',
        },
        cancelToken: this.cancelToken.token,
      })
      .then((response) => {
        this.cancelToken = null
        if (this.status === 0) {
          // aborted
          return
        }
        if (success) {
          success(response.data)
        }
      })
      .catch((error) => {
        this.cancelToken = null
        if (axios.isCancel(error)) {
          // request canceled
        } else if (this.status === 0) {
          // request aborted
        } else {
          // regular failure flow
          if (failure) {
            failure()
          }
        }
      })
  }

  public abort(): void {
    this.status = 0
    if (this.cancelToken) {
      this.cancelToken.cancel()
      this.cancelToken = null
    }
  }
}

export function initializeAutocompleteField (
  autocompleteEl: HTMLSelectElement,
  modalParent?: HTMLElement,
  initializedCallback?: () => void,
  optionsFn?: (options: Select2Options) => Select2Options
  ): JQuery<HTMLSelectElement> | null {
  const $autocompleteEl = $(autocompleteEl)
  if ($autocompleteEl.hasClass('select2-hidden-accessible')) {
    // Select2 has been initialized
    return null
  }

  const allowOther = $(autocompleteEl).data('allow-other')

  const additionalClass = $(autocompleteEl).hasClass('is-invalid') ? 'is-invalid' : ''

  const autocompleteParams: Select2Options = {
    allowClear: true,
    placeholder: autocompleteEl.dataset.placeholder || '',
    containerCssClass: additionalClass,
    dropdownCssClass: additionalClass
  }

  if (modalParent instanceof HTMLElement) {
    autocompleteParams['dropdownParent'] = $(modalParent)
  }

  if (allowOther) {
    autocompleteParams['tags'] = true
    if ($(autocompleteEl).data('tags') !== 'simple') {
      autocompleteParams['createTag'] = autocompleteCreateTag
      autocompleteParams['sorter'] = autocompleteResultsSorter
    }
  }

  const ajaxUrl = autocompleteEl.dataset.autocompleteUrl

  if (ajaxUrl !== 'undefined' && ajaxUrl != null) {
    autocompleteParams['minimumInputLength'] = autocompleteEl.dataset.autocompletePreload ? 0 : 2
    autocompleteParams['ajax'] = {
      url: (): string => {
        return ajaxUrl
      },
      dataType: 'json',
      transport: function (settings, success, failure): Select2AxiosTransport | undefined {

        let requestUrl = settings.url
        if (requestUrl) {
          const requestData = settings.data
          if (typeof requestData !== 'string') {
            if (requestData && requestData.term) {
              const uri = new URI(requestUrl)
                uri.addSearch('term', requestData['term'])
                requestUrl = uri.toString()
            }
          }

          const transport = new Select2AxiosTransport(requestUrl, success, failure)
          return transport
        }
      }
    }
  }

  autocompleteParams['width'] = '100%'

  if (isNonEmptyString(autocompleteEl.dataset.autocompleteWidth)) {
    autocompleteParams['width'] = autocompleteEl.dataset.autocompleteWidth
  }

  let select2Options = autocompleteParams
  if (typeof optionsFn === 'function') {
    select2Options = optionsFn(select2Options)
  }
  const select2El = $(autocompleteEl).select2(select2Options)

  // handles focus issue with jquery 3.6.0
  // https://github.com/select2/select2/pull/6044 , https://github.com/select2/select2/issues/5993
  $(autocompleteEl).on('select2:open', () => {
    const dropdownEl = $(autocompleteEl).data('select2')?.$dropdown?.get(0)
    if (dropdownEl instanceof HTMLElement) {
      const searchEl = dropdownEl.querySelector('input[type="search"]')
      if (searchEl instanceof HTMLElement) {
        searchEl.focus()
      }
    }
  })

  if ($autocompleteEl.hasClass('form-control-sm') || $autocompleteEl.hasClass('custom-select-sm')) {
    select2El.data('select2').$container.addClass('autocomplete-sm')
  }
  if (typeof initializedCallback === 'function') {
    initializedCallback()
  }

  return select2El
}

export function initializeFormFields(el: HTMLElement): void {
  // check if in modal
  const modalParent = el.closest('.modal-dialog')
  const elements = el.querySelectorAll(':scope select.form-control.autocomplete, :scope select.form-control[data-autocomplete=true], :scope select.custom-select.autocomplete, :scope select.custom-select[data-autocomplete=true]')
  for (let i = 0; i < elements.length; i++) {
    const autocompleteEl = elements[i]
    if (autocompleteEl instanceof HTMLSelectElement) {
      if (autocompleteEl.dataset.autoInit != 'false') {
        initializeAutocompleteField(autocompleteEl, modalParent instanceof HTMLElement ? modalParent : undefined)
      }
    }
  }

  const dpElements = el.querySelectorAll(':scope .input-group.date')
  for (let i = 0; i < dpElements.length; i++) {
    const dpEl = dpElements[i]
    if (dpEl instanceof HTMLElement) {
      initializeDatePicker(dpEl)
    }
  }

  const uploadElements = el.querySelectorAll(':scope [data-is="upload-field"]')
  for (let i = 0; i < uploadElements.length; i++) {
    const upEl = uploadElements[i]
    if (upEl instanceof HTMLElement) {
      initializeUploadField(upEl)
    }
  }

  const uploadFilesElements = el.querySelectorAll(':scope [data-is="upload-files-field"]')
  for (let i = 0; i < uploadFilesElements.length; i++) {
    const upEl = uploadFilesElements[i]
    if (upEl instanceof HTMLElement) {
      initializeMultiUploadField(upEl)
    }
  }
}

export function simulateFormPost(url: string, params?: Record<string, any>): void {
  const csrfToken = Rails.csrfToken()
  const csrfParam = Rails.csrfParam()

  const formEl = document.createElement('form');
  formEl.method = 'POST'
  formEl.action = url
  formEl.style.display = 'none'

  const methodInputEl = document.createElement('input')
  methodInputEl.type = 'hidden'
  methodInputEl.name = '_method'
  methodInputEl.value = 'POST'
  formEl.append(methodInputEl)

  if (!Rails.isCrossDomain(url) && csrfParam != null && csrfToken != null) {
    const csrfInputEl = document.createElement('input')
    methodInputEl.type = 'hidden'
    methodInputEl.name = csrfParam
    methodInputEl.value = csrfToken
    formEl.append(csrfInputEl)
  }

  if (params != null) {
    for (const paramName of Object.keys(params)) {
      const paramEl = document.createElement('input')
      paramEl.type = 'hidden'
      paramEl.name = paramName
      paramEl.value = (params as any)[paramName]
      formEl.append(paramEl)
    }
  }

  const submitEl = document.createElement('input')
  submitEl.type = 'submit'
  formEl.append(submitEl)

  document.body.appendChild(formEl);
  submitEl.click()
}

export function updateFieldsByTags(parentNode: HTMLElement) {
  let activeTags: string[] = []
  parentNode.querySelectorAll(":scope select[data-active-field-tag]").forEach((value) => {
    const selectedOptions = (value as HTMLSelectElement).selectedOptions
    for(let i = 0; i < selectedOptions.length; i++) {
      const option = selectedOptions.item(i)
      const tags = option?.dataset?.activeFieldTags
      if (isNonEmptyString(tags)) {
        const tagItems = tags.split(/\s+/)
        if (tagItems.length > 0) {
          activeTags = activeTags.concat(tagItems)
        }
      }
    }
  })

  parentNode.querySelectorAll(":scope [data-field-tag]").forEach((el) => el.classList.add("d-none"))
  for(const tag of activeTags) {
    parentNode.querySelectorAll(`:scope [data-field-tag~="${tag}"]`).forEach((el) => el.classList.remove("d-none"))
  }
}

export function registerFieldsByTagsTriggers(parentEl?: HTMLElement) {
  (parentEl || document).querySelectorAll(":scope select[data-active-field-tag]").forEach((value) => {
    value.addEventListener("change", () => {
      const formEl = value.closest("form")
      if (formEl) {
        updateFieldsByTags(formEl)
      }
    })
  })
}
