import i18next from 'i18next'
import Backend from 'i18next-chained-backend'
import LocalStorageBackend from 'i18next-localstorage-backend' // primary use cache
import HttpApi from 'i18next-http-backend' // fallback xhr load

document.addEventListener('DOMContentLoaded', (): void => {

  let curLng = document.body.dataset.lng
  if (typeof curLng  === 'string' && curLng.length > 0 ) {
    curLng = curLng
  } else {
    curLng = 'en'
  }

  const versions:any = {}

  versions[curLng] = document.body.dataset.lngV || null

  let i18nPrefix = 'i18next_res_'
  const prefixMetaEl = document.querySelector('meta[name="i18n-prefix"]')
  if (prefixMetaEl instanceof HTMLMetaElement) {
    i18nPrefix = `${prefixMetaEl.content}_`
  }

  i18next
    .use(Backend)
    .init({
      lng: curLng,
      debug: false,
      // resources: resources,
      backend: {
        backends: [
          LocalStorageBackend,  // primary
          HttpApi               // fallback
        ],
        backendOptions: [{
          // prefix for stored languages
          prefix: i18nPrefix,

          // expiration
          expirationTime: 7*24*60*60*1000,

          // language versions
          versions: versions,

          // can be either window.localStorage or window.sessionStorage. Default: window.localStorage
          store: window.sessionStorage
        }, {
          loadPath: '/locales/{{lng}}/{{ns}}.json' // xhr load path for my own fallback
        }]
      }
    });
  })
