<template>
  <ts-modal
    ref="modal"
    modal-class="modal-confirmation"
    :modal-title="confirmation.title"
    :modal-dialog-class="confirmation.modalDialogClass"
  >
    <template #modal-content>
      <slot
        name="confirmation"
        :context="confirmation.context"
      >
        <alert
          :message="confirmation.message"
          :dismissable="false"
          additional-css-class="text-center"
          :type="confirmation.type"
        />
      </slot>
      <div
        v-if="requireConfirmationCode"
        class="confirmation"
      >
        <div class="code">
          Enter confirmation code to proceed <span class="code">{{ confirmation.confirmationCode }}</span>
        </div>
        <div class="form-group">
          <input
            v-model="confirmationCode"
            type="text"
            :class="['form-control form-control-sm', {'is-invalid': confirmationCodeInvalid}]"
          >
          <div
            v-if="confirmationCodeInvalid"
            class="invalid-feedback"
          >
            Invalid confirmation code
          </div>
        </div>
      </div>
    </template>
    <template #modal-footer>
      <button
        type="button"
        class="btn btn-sm btn-secondary"
        data-dismiss="modal"
      >
        {{ confirmation.cancelActionTitle }}
      </button>
      <button
        :class="`btn btn-sm btn-${confirmation.type}`"
        @click="onConfirmAction"
      >
        {{ confirmation.confirmActionTitle }}
      </button>
    </template>
  </ts-modal>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import Rails from '@rails/ujs'
import TsModal from './ts-modal.vue'
import { isNonEmptyString } from '../js-utils'

import Alert from './alert.vue'

export interface ConfirmationConfiguration {
  title: string;
  message: string;
  type: 'danger' | 'primary' | 'info';
  cancelActionTitle: string;
  confirmActionTitle: string;
  confirmationCode: string | null;
  context: any;
  modalDialogClass?: string | null;
}

type ConfirmationFn = () => void;

export default defineComponent({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  components: { Alert, TsModal },
  data () {
    return {
      triggerEl: null as HTMLElement | null,
      confirmationCode: '',
      confirmationCodeInvalid: false,
      confirmation: {
        title: 'Action confirmation',
        message: 'Are you sure?',
        type: 'danger',
        cancelActionTitle: 'Cancel',
        confirmActionTitle: 'Confirm',
        confirmationCode: null,
        context: null,
      } as ConfirmationConfiguration,
      confirmationFn: null as ConfirmationFn | null,
    }
  },
  computed: {
    requireConfirmationCode (): boolean {
      return isNonEmptyString(this.confirmation.confirmationCode)
    }
  },
  methods: {
    show(triggerEl: HTMLElement | null, confirmationCfg: ConfirmationConfiguration, confirmationFn?: ConfirmationFn): void {
      if (!this.$refs.modal.visible) {
        this.triggerEl = triggerEl
        this.confirmation = confirmationCfg
        this.confirmationCode = ''
        this.confirmationCodeInvalid = false
        this.confirmationFn = confirmationFn ? confirmationFn : null
        this.$refs.modal.show()
      }
    },

    hide(): void {
      this.$refs.modal.hide()
    },

    onConfirmAction (): void {
      this.confirmationCodeInvalid = false
      if (isNonEmptyString(this.confirmation.confirmationCode)) {
        if (this.confirmation.confirmationCode !== this.confirmationCode) {
          this.confirmationCodeInvalid = true
          return
        }
      }
      this.$emit('confirmed', this.confirmation.context)
      if (this.confirmationFn) {
        this.confirmationFn()
      }

      if (this.triggerEl) {
        const old = Rails.confirm
        Rails.confirm = function(): boolean { return true }
        this.triggerEl.click();
        Rails.confirm = old;
      }
    }
  }
})
</script>
