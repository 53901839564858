<template>
  <ts-modal
    ref="modal"
    css-class="modal-alert"
    :modal-title="title"
    :keyboard="true"
  >
    <template #modal-content>
      <div class="modal-body">
        <div
          :class="['alert', `alert-${type}`, 'mb-0']"
          role="alert"
        >
          {{ message }}
        </div>
      </div>
    </template>
    <template #modal-footer>
      <button
        v-if="buttons.length == 0"
        type="button"
        class="btn btn-sm btn-secondary"
        data-dismiss="modal"
      >
        {{ closeButtonTitle }}
      </button>
      <template v-else>
        <button
          v-for="(button, buttonIdx) of buttons"
          :key="buttonIdx"
          type="button"
          :class="['btn btn-sm', button.cssClass]"
          @click.prevent="$emit('modal-button-click', button)"
        >
          {{ button.title }}
        </button>
      </template>
    </template>
  </ts-modal>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import TsModal from './ts-modal.vue'

export interface ModalButton {
  title: string;
  cssClass: string;
  value: any;
}

export default defineComponent({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  components: { TsModal },
  props: {
    closeButtonTitle: { default: 'Close', type: String }
  },
  data () {
    return {
      title: '',
      message: '',
      type: '',
      buttons: [] as ModalButton[]
    }
  },
  methods: {
    show(title: string, message: string, type: string, buttons?: ModalButton[]): void {
      this.title = title
      this.message = message
      this.type = type
      if (Array.isArray(buttons)) {
        this.buttons = buttons
      }

      this.$refs.modal.show()
    },

    hide(): void {
      this.$refs.modal.hide()
    }
  }
})
</script>
