import { tsInitVue } from './js-utils'
import UploadField from './vue-components/upload-field.vue'
import UploadFilesField from './vue-components/upload-files-field.vue'

export function initializeUploadField(el: HTMLElement): void {
  const uploadUrl = el.dataset.uploadUrl
  tsInitVue(UploadField, el, {
    uploadUrl
  })
}

export function initializeMultiUploadField(el: HTMLElement): void {
  const uploadUrl = el.dataset.uploadUrl
  tsInitVue(UploadFilesField, el, {
    uploadUrl
  })
}
