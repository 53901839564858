<template>
  <label
    :class="{ 'btn btn-sm btn-primary': true, disabled: disabled }"
    @click="onUploadClick"
  >
    <span class="glyphicons icon-left upload align-middle" /><span class="align-middle">{{ title || $t('upload_button.select_file') }}</span>
    <input
      :id="inputId"
      ref="uploadField"
      type="file"
      :name="inputName"
      style="display: none"
      :disabled="disabled"
      @change="uploadFile"
    >
  </label>
</template>

<script lang="ts">
import {defineComponent} from "vue"
  import axios from "axios"
  import Rails from "@rails/ujs"

  export default defineComponent({
    props: {
      title: { default: null, type: String },
      uploadUrl: { default: null, type: String },
      uploadData: { default: null, type: Object },
      disabledMessage: { default: null, type: String },
      inputName: { default: null, type: String },
      inputId: { default: null, type: String }
    },

    data () {
      return {
        isUploading: false,
        uploadProgress: 0
      }
    },

    computed: {
      hasUploadUrl (): boolean {
        return typeof this.uploadUrl === 'string' && this.uploadUrl.length > 0
      },
      disabled (): boolean {
        return this.isUploading || !this.hasUploadUrl
      }
    },

    methods: {
      uploadFile (): void {
        if (!this.hasUploadUrl) {
          alert('No upload URL defined!')
          return
        }

        if (this.disabled) {
          return
        }

        const uploadField = this.$refs.uploadField
        if (!uploadField.files) {
          return
        }

        const file = uploadField.files[0]
        if (file === null) {
          // IE 11 will fire change event when setting uploadField.value to ''
          // if file is null, then do not anything
          return
        }

        const formData = new FormData()
        formData.append('file', file)
        if (this.uploadData) {
          for (const prop in this.uploadData) {
            formData.append(prop, this.uploadData[prop])
          }
        }

        // IE 11 will fire change event again
        uploadField.value = ''

        this.isUploading = true
        this.uploadProgress = 0

        this.$emit('upload-progress', this.isUploading, this.uploadProgress, 'Uploading file ...')

        axios.post(this.uploadUrl, formData, {
          headers: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'X-Requested-With': 'XMLHttpRequest',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'X-CSRF-Token': Rails.csrfToken()
          },
          onUploadProgress: (progressEvent) => {
            this.uploadProgress = Math.floor(progressEvent.loaded / progressEvent.total * 100)
            if (progressEvent.loaded === progressEvent.total) {
              this.$emit('upload-progress', this.isUploading, this.uploadProgress, 'Processing file ...')
            } else {
              this.$emit('upload-progress', this.isUploading, this.uploadProgress, 'Uploading file ...')
            }
          }
        })
        .then((response) => {
          let error = null as string | null
          if (response.data) {
            if (response.data) {
              if (response.data.error) {
                error = response.data.error
              } else {
                this.$emit('uploaded', response.data)
              }
            } else {
              error = 'Invalid response from server'
            }
          }
          this.isUploading = false
          this.$emit('upload-progress', this.isUploading)
          if (error) {
            this.$emit('upload-error', error)
          }
        })
        .catch((error) => {
          this.isUploading = false
          this.$emit('upload-error', error.message)
        })
      },

      onUploadClick (e: Event): void {
        if (this.disabled) {
          e.preventDefault()
          return
        }

        if (typeof this.disabledMessage === 'string' && this.disabledMessage.length > 0) {
          alert(this.disabledMessage)
          e.preventDefault()
        }
      }
    }
  })
</script>
