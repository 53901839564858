<template>
  <div :class="[ 'input-group', { 'input-group-sm': small }, 'date', { 'datetime': showTime }, { 'is-invalid': invalid } ]">
    <input
      ref="date"
      v-model="dateTime.date"
      class="form-control date"
      type="text"
    >
    <div class="input-group-append">
      <button
        ref="dateToggler"
        type="button"
        class="btn btn-picker btn-outline-secondary"
      >
        <span class="glyphicons calendar" />
      </button>
    </div>
    <template v-if="showTime">
      <input
        ref="time"
        v-model="dateTime.time"
        class="form-control time ui-timepicker-input"
        autocomplete="off"
        type="text"
      >
      <div class="input-group-append ui-timepicker-trigger">
        <button
          ref="timeToggler"
          type="button"
          class="btn btn-picker btn-outline-secondary"
        >
          <span class="glyphicons time" />
        </button>
      </div>
    </template>
  </div>
</template>
<script lang="ts">
import {defineComponent} from 'vue'
  import $ from 'jquery'
  import { isNonEmptyString } from '@/js/js-utils'

  export interface DateTimeValue {
    date: any;
    time: any;
  }

  export default defineComponent({
    name: 'DatePicker',
    props: {
      value: { default: (): DateTimeValue => { return { date: null, time: null } }, type: Object as () => DateTimeValue },
      small: { default: false, type: Boolean },
      invalid: { default: false, type: Boolean },
      minDate: { default: null, type: String },
      showTime: { default: true, type: Boolean },
    },
    data () {
      return {
        dateTime: this.value as DateTimeValue
      }
    },
    watch: {
      value (newValue: DateTimeValue): void {
        if (newValue) {
          this.dateTime = JSON.parse(JSON.stringify(newValue))
        } else {
          this.dateTime = {
            date: null,
            time: null,
          }
        }
      },

      minDate (_newValue: string): void {
        this.updateMinDate()
      }
    },
    mounted () {
      const dateInputEl = this.$refs.date

      $(dateInputEl).datepicker({
        trigger: this.$refs.dateToggler,
        autoHide: true,
        format: 'dd/mm/yyyy',
        weekStart: 1,
        zIndex: 2048, // otherwise will be hidden behind a modal
      })

      // dateInputEl.addEventListener('change', (e) => {
      $(dateInputEl).on('change', (e) => {
        if (e.currentTarget instanceof HTMLInputElement) {
          this.dateTime.date = e.currentTarget.value
          this.$emit('input', this.dateTime)
        }
      })


      const syncDateRef = (this.$el as HTMLElement).dataset.dateSyncRef
      if (syncDateRef) {
        $(dateInputEl).on("show.datepicker", function() {
          const fromDpInputEl = document.querySelector(`[data-date-ref="${syncDateRef}"] input.form-control.date`)
          if (fromDpInputEl instanceof HTMLInputElement) {
            const initialDateStr = fromDpInputEl.value
            if (!isNonEmptyString(dateInputEl.value)) {
              const dp = $(dateInputEl).data('datepicker')
              if (dp) {
                const initialDate = dp.parseDate(isNonEmptyString(initialDateStr) ? initialDateStr : new Date())
                dp.viewDate = new Date(initialDate);
                dp.initialDate = new Date(initialDate);
              }
            }
          }
        })
      }

      const timeInputEl = this.$refs.time
      if (timeInputEl) {
        $(timeInputEl).timepicker({
          timeFormat: 'H:i',
          step: 30,
          scrollDefault: '09:00',
          showOn: [],
          showOnFocus: false,
          stopScrollPropagation: true,
          wrapperHTML: '<div><div class="tp-wrapper" data-container="timepicker-list"></div></div>',
          offsetBottom: 10,
          offsetTop: -10
        })

        $(timeInputEl).on('change', (e) => {
          if (e.currentTarget instanceof HTMLInputElement) {
            this.dateTime.time = e.currentTarget.value
            this.$emit('input', this.dateTime)
          }
        })

        if (this.$refs.timeToggler) {
          this.$refs.timeToggler.addEventListener('click', function (e) {
            e.preventDefault()
            const $tpEl = $(timeInputEl)
            if ($tpEl.timepicker('isVisible')) {
              $tpEl.timepicker('hide')
            } else {
              $tpEl.timepicker('show')
            }
          })
        }
      }

      this.updateMinDate()
    },
    updated () {
      this.updateMinDate()
    },
    methods: {
      updateMinDate (): void {
        $(this.$refs.date).datepicker('setStartDate', this.minDate)
      }
    }
  })
</script>
