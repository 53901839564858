<script setup lang="ts">

import {computed, useSlots} from "vue";
import {isNonEmptyString} from "../js-utils";

export interface Props {
  type?: string
  dismissable?: boolean
  message?: string | null
  detailsHTML?: string | null
  additionalCssClass?: string
}

const props = withDefaults(defineProps<Props>(), {
  type: "danger",
  dismissable: true,
  message: null,
  detailsHTML: null,
  additionalCssClass: "",
})

const alertClass = computed((): string => {
  let dismissibleClass = ''
  if (props.dismissable) {
    dismissibleClass = 'alert-dismissible'
  }
  return `alert alert-sm alert-${props.type} ${dismissibleClass} fade show ${props.additionalCssClass}`
})

const showAlert = computed((): boolean => isNonEmptyString(props.message))

const showDetails = computed((): boolean => {
  const slots = useSlots()
  return (slots.default && true) || isNonEmptyString(props.detailsHTML)
})

defineEmits<{
  (e: 'dismiss'): void
}>()

</script>

<template>
  <div
    v-if="showAlert"
    :class="alertClass"
    role="alert"
  >
    {{ message }}
    <button
      v-if="dismissable"
      type="button"
      class="close"
      aria-label="Close"
      @click.prevent="$emit('dismiss')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
    <template v-if="showDetails">
      <hr>
      <div v-html="detailsHTML" />
      <slot />
    </template>
  </div>
</template>
