<script lang="ts" setup>
import {computed, onMounted, ref} from "vue";
import axios from 'axios'
import Rails from '@rails/ujs'
import {isNonEmptyString} from '../js-utils'
import type {ReimbursementFile} from './types'
import {useTranslation} from "i18next-vue";

import Alert from './alert.vue'

const {t: $t} = useTranslation()

const props = defineProps({
  uploadUrl: {default: null, type: String},
  initialFiles: {default: null, type: Array as () => ReimbursementFile[]},
  requireAttendanceCertificate: {default: false, type: Boolean},
  withAgendaFile: {default: false, type: Boolean},
  requireAgendaFile: {default: false, type: Boolean},
  filesTitle: {default: 'Reimbursement files', type: String},
  withAdditionalFiles: {default: true, type: Boolean},
  showAgendaFileInfo: {default: true, type: Boolean},
})

const isUploading = ref(false)
const uploadProgress = ref(0)
const uploadError = ref<string | null>(null)
const progressMessage = ref<string | null>(null)
const files = ref<ReimbursementFile[]>([])
const certificateFile = ref<ReimbursementFile | null>(null)
const agendaFile = ref<ReimbursementFile | null>(null)
const uploadFileId = ref<string | null>(null)

const uploadCertificateField = ref<HTMLInputElement | null>(null)
const uploadAgendaField = ref<HTMLInputElement | null>(null)

const canUpload = computed((): boolean => isNonEmptyString(props.uploadUrl))
const hasFiles = computed((): boolean => files.value.length > 0)
const noFiles = computed((): boolean => files.value.length === 0)
const uploadProgressStyle = computed((): any => {
  return {
    width: `${uploadProgress.value}%`,
    'min-width': '3rem'
  }
})
const hasCertificateFile = computed((): boolean => typeof certificateFile.value !== 'undefined' && certificateFile.value !== null)
const hasAgendaFile = computed((): boolean => typeof agendaFile.value !== 'undefined' && agendaFile.value !== null)

onMounted(() => {
  if (Array.isArray(props.initialFiles)) {
    const newFiles = []
    for (const fileData of props.initialFiles) {
      const file = JSON.parse(JSON.stringify(fileData))
      if (file.typeId === 1) {
        certificateFile.value = file
      } else if (file.typeId === 4) {
        agendaFile.value = file
      } else if (file.typeId != 5) { // skip Finance exception files
        newFiles.push(file)
      }
    }
    files.value = newFiles
  }
})

function uploadCertificateFile(): void {
  if (uploadCertificateField.value) {
    uploadFile(uploadCertificateField.value, true, false)
  }
}

function uploadAgendaFile(): void {
  if (uploadAgendaField.value) {
    uploadFile(uploadAgendaField.value, false, true)
  }
}

function uploadReimbursementFile(e: Event, file?: ReimbursementFile): void {
  if (e.target instanceof HTMLInputElement) {
    uploadFile(e.target, false, false, file)
  }
}

function clearError(): void {
  uploadError.value = null
}

function removeFile(file: ReimbursementFile | null): void {
  if (file == null) {
    return
  }

  if (confirm($t('reimbursement_manager.confirmation'))) {
    file.rm = true
  }
}

function restoreFile(file: ReimbursementFile | null): void {
  if (file == null) {
    return
  }

  if (confirm($t('reimbursement_manager.confirmation'))) {
    file.rm = false
  }
}

function uploadFile(
  uploadField: HTMLInputElement,
  isCertificate: boolean,
  isAgenda: boolean,
  file?: ReimbursementFile
): void {
  if (!canUpload.value) {
    return
  }

  if (uploadField.files == null || uploadField.files.length == 0) {
    return
  }

  uploadFileId.value = file ? file.id : null

  const uploadFile = uploadField.files[0]
  if (uploadFile === null) {
    // IE 11 will fire change event when setting uploadField.value to ''
    // if file is null, then do not anything
    return
  }

  const formData = new FormData()
  if (file) {
    formData.append('id', file.id)
    formData.append('file_id', file.id) // should transition backend to file_id because on preview form id is event
    formData.append('file_title', typeof file.title === 'string' && file.title.length > 0 ? file.title : '')
  }
  formData.append('file', uploadFile)
  if (isCertificate) {
    formData.append('type_id', '1')
  } else if (isAgenda) {
    formData.append('type_id', '4')
  }

  // IE 11 will fire change event again
  uploadField.value = ''

  isUploading.value = true
  uploadProgress.value = 0
  progressMessage.value = 'Uploading file ...'
  uploadError.value = null

  axios.post(props.uploadUrl, formData, {
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'X-Requested-With': 'XMLHttpRequest',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'X-CSRF-Token': Rails.csrfToken()
    },
    onUploadProgress: (progressEvent) => {
      uploadProgress.value = Math.floor(progressEvent.loaded / progressEvent.total * 100)
      if (progressEvent.loaded === progressEvent.total) {
        progressMessage.value = 'Processing file ...'
      }
    }
  })
    .then((response) => {
      if (response.data) {
        if (response.data.file) {
          if (isCertificate) {
            certificateFile.value = response.data.file
          } else if (isAgenda) {
            agendaFile.value = response.data.file
          } else {
            // check if we have a file with the same id
            const fileId = response.data.file.id
            let fileUpdated = false
            for (let i = 0; i < files.value.length; i++) {
              if (files.value[i].id === fileId) {
                files.value.splice(i, 1, response.data.file)
                fileUpdated = true
              }
            }
            if (!fileUpdated) {
              files.value.push(response.data.file)
            }
          }
        } else if (response.data.error) {
          uploadError.value = response.data.error
        } else {
          uploadError.value = 'Invalid response from server'
        }
      }
      isUploading.value = false
    })
    .catch((error) => {
      uploadError.value = error.message
      if (error.response && error.response.data && isNonEmptyString(error.response.data.error)) {
        uploadError.value = error.response.data.error
      }

      isUploading.value = false
    })
}

</script>

<template>
  <div class="with-rapid-form">
    <div class="files">
      <div v-if="requireAttendanceCertificate">
        <div class="card files">
          <div class="card-header">
            <span class="align-middle">Certificate of participation</span>
            <label
              v-if="canUpload && !isUploading"
              class="btn btn-sm btn-primary float-right"
            >
              <span class="glyphicons icon-left upload" /><span>upload</span>
              <input
                id="certificate-field"
                ref="uploadCertificateField"
                style="display: none"
                type="file"
                @change="uploadCertificateFile"
              >
            </label>
          </div>
          <div
            v-if="!hasCertificateFile"
            class="card-body bg-warning"
          >
            Certificate of participation is required!
          </div>
          <div
            v-if="hasCertificateFile && certificateFile"
            :class="{ 'card-body': true, 'bg-warning': certificateFile.rm }"
          >
            <div class="form-row">
              <input
                :value="certificateFile.id"
                name="event_candidate[reimbursement_files_attributes][][id]"
                type="hidden"
              >
              <input
                :value="certificateFile.rm ? 1 : 0"
                name="event_candidate[reimbursement_files_attributes][][_destroy]"
                type="hidden"
              >
              <div class="col col-12 col-md form-group">
                <label>Description</label>
                <input
                  :value="certificateFile.title"
                  class="form-control form-control-sm"
                  name="event_candidate[reimbursement_files_attributes][][title]"
                  type="text"
                >
              </div>
              <div class="col form-group">
                <label>{{ $t('reimbursement_manager.file') }}</label>
                <input
                  :value="certificateFile.filename"
                  class="form-control form-control-sm"
                  readonly
                  type="text"
                >
              </div>
              <div class="col col-auto form-group-button">
                <button
                  v-if="!certificateFile.rm"
                  class="btn btn-sm btn-danger"
                  type="button"
                  @click="removeFile(certificateFile)"
                >
                  {{ $t('reimbursement_manager.delete') }}
                </button>
                <button
                  v-if="certificateFile.rm"
                  class="btn btn-sm btn-info"
                  type="button"
                  @click="restoreFile(certificateFile)"
                >
                  {{ $t('reimbursement_manager.restore') }}
                </button>
              </div>
            </div>
            <div
              v-if="certificateFile.rm"
              class="text-danger font-italic"
            >
              {{ $t('reimbursement_manager.restore_certificate_notice') }}
            </div>
          </div>
        </div>
      </div>

      <div v-if="withAgendaFile">
        <div
          class="card files"
          data-test-id="agenda-card"
        >
          <div class="card-header">
            <span class="align-middle">{{ $t('reimbursement_manager.agenda_title') }}</span>
            <label
              v-if="canUpload && !isUploading"
              class="btn btn-sm btn-primary float-right"
            >
              <span class="glyphicons icon-left upload" /><span>upload</span>
              <input
                id="agenda-field"
                ref="uploadAgendaField"
                style="display: none"
                type="file"
                @change="uploadAgendaFile"
              >
            </label>
          </div>
          <div
            v-if="requireAgendaFile"
            class="card-body bg-warning"
          >
            {{ $t('reimbursement_manager.agenda_required') }}
          </div>
          <div
            v-if="!hasAgendaFile && props.showAgendaFileInfo"
            class="card-body"
          >
            {{ $t('reimbursement_manager.agenda_details') }}
          </div>
          <div
            v-if="hasAgendaFile && agendaFile"
            :class="{ 'card-body': true, 'bg-warning': agendaFile.rm }"
          >
            <div class="form-row">
              <input
                :value="agendaFile.id"
                name="event_candidate[reimbursement_files_attributes][][id]"
                type="hidden"
              >
              <input
                :value="agendaFile.rm ? 1 : 0"
                name="event_candidate[reimbursement_files_attributes][][_destroy]"
                type="hidden"
              >
              <div class="col col-12 col-md form-group">
                <label>Description</label>
                <input
                  :value="agendaFile.title"
                  class="form-control form-control-sm"
                  name="event_candidate[reimbursement_files_attributes][][title]"
                  type="text"
                  data-test-id="file-title"
                >
              </div>
              <div class="col form-group">
                <label>{{ $t('reimbursement_manager.file') }}</label>
                <input
                  :value="agendaFile.filename"
                  class="form-control form-control-sm"
                  readonly
                  type="text"
                  data-test-id="file-name"
                >
              </div>
              <div class="col col-auto form-group-button">
                <button
                  v-if="!agendaFile.rm"
                  class="btn btn-sm btn-danger"
                  type="button"
                  @click="removeFile(agendaFile)"
                >
                  {{ $t('reimbursement_manager.delete') }}
                </button>
                <button
                  v-if="agendaFile.rm"
                  class="btn btn-sm btn-info"
                  type="button"
                  @click="restoreFile(agendaFile)"
                >
                  {{ $t('reimbursement_manager.restore') }}
                </button>
              </div>
            </div>
            <div
              v-if="agendaFile.rm"
              class="text-danger font-italic"
            >
              {{ $t('reimbursement_manager.restore_notice') }}
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="props.withAdditionalFiles"
        class="card files"
        data-test-id="additional-files-card"
      >
        <div class="card-header">
          <span class="align-middle">{{ filesTitle }}</span>
        </div>
        <div
          v-if="hasFiles"
          class="list-group list-group-flush"
        >
          <div
            v-for="file in files"
            :key="file.id"
            :class="{ 'list-group-item': true, 'list-group-item-danger': file.rm }"
          >
            <div class="form-row">
              <input
                :value="file.id"
                name="event_candidate[reimbursement_files_attributes][][id]"
                type="hidden"
              >
              <input
                :value="file.rm ? 1 : 0"
                name="event_candidate[reimbursement_files_attributes][][_destroy]"
                type="hidden"
              >
              <div class="col col-12 col-md form-group">
                <label>Description</label>
                <input
                  v-model="file.title"
                  :placeholder="file.titlePlaceholder"
                  class="form-control form-control-sm"
                  name="event_candidate[reimbursement_files_attributes][][title]"
                  type="text"
                >
              </div>
              <div class="col form-group">
                <label>{{ $t('reimbursement_manager.file') }}</label>
                <div class="input-group">
                  <input
                    :value="file.filename"
                    class="form-control form-control-sm"
                    readonly
                    type="text"
                    data-test-id="file-name"
                  >
                  <div class="input-group-append">
                    <label
                      v-if="canUpload && !isUploading"
                      class="btn btn-primary btn-sm"
                    >
                      <span class="glyphicons icon-left upload" /><span>{{ file.hasFile ? 'Change file' : $t('reimbursement_manager.upload_file') }}</span>
                      <input
                        style="display: none"
                        type="file"
                        @change="uploadReimbursementFile($event, file)"
                      >
                    </label>
                  </div>
                </div>
              </div>
              <div class="col col-auto form-group-button">
                <button
                  v-if="!file.rm"
                  class="btn btn-sm btn-danger"
                  type="button"
                  @click="removeFile(file)"
                >
                  {{ $t('reimbursement_manager.delete') }}
                </button>
                <button
                  v-if="file.rm"
                  class="btn btn-sm btn-info"
                  type="button"
                  @click="restoreFile(file)"
                >
                  {{ $t('reimbursement_manager.restore') }}
                </button>
              </div>
            </div>
            <div
              v-if="file.rm"
              class="text-danger font-italic"
            >
              {{ $t('reimbursement_manager.restore_notice') }}
            </div>
            <alert
              v-if="uploadFileId == file.id"
              :message="uploadError"
              type="danger"
              @dismiss="clearError"
            />
            <div v-if="isUploading && uploadFileId == file.id">
              <div class="upload-progress">
                <div class="progress">
                  <div
                    :style="uploadProgressStyle"
                    aria-valuemax="100"
                    aria-valuemin="0"
                    aria-valuenow="25"
                    class="progress-bar"
                    role="progressbar"
                  >
                    {{ uploadProgress }}%
                  </div>
                </div>
                <div class="status-label text-secondary">
                  {{ progressMessage }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="noFiles"
          class="card-body"
        >
          No files uploaded yet
        </div>
        <div class="card-footer">
          <label :class="{ 'btn btn-sm btn-primary': true, 'disabled': !canUpload || isUploading }">
            <span class="glyphicons icon-left upload" /><span>{{ $t('reimbursement_manager.add_new_file') }}</span>
            <input
              :disabled="!canUpload || isUploading"
              style="display: none"
              type="file"
              @change="uploadReimbursementFile"
            >
          </label>
        </div>
      </div>
    </div>

    <alert
      v-if="!uploadFileId"
      :message="uploadError"
      type="danger"
      @dismiss="clearError"
    />
    <div v-if="isUploading && !uploadFileId">
      <!-- loading-indicator :message="progressMessage"/ -->
      <div class="upload-progress">
        <div class="progress">
          <div
            :style="uploadProgressStyle"
            aria-valuemax="100"
            aria-valuemin="0"
            aria-valuenow="25"
            class="progress-bar"
            role="progressbar"
          >
            {{ uploadProgress }}%
          </div>
        </div>
        <div class="status-label text-secondary">
          {{ progressMessage }}
        </div>
      </div>
    </div>
  </div>
</template>
