<script setup lang="ts">
import $ from "jquery"
import {nextTick, ref} from "vue";

export interface Props {
    modalTitle?: string
    modalClass?: string | null
    modalDialogClass?: string | null
    keyboard?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  modalTitle: '',
  modalClass: null,
  modalDialogClass: null,
  keyboard: true
})

const modal = ref<HTMLDivElement | null>(null)

const modalVisible = ref(false)
const modalEventsAttached = ref(false)

const emit = defineEmits<{
  (e: 'modal-hidden'): void
  (e: 'modal-shown'): void
}>()

const show = () => {
  if (!modalEventsAttached.value) {
    const modalEl = modal.value
    if (modalEl) {
      modalEventsAttached.value = true

      $(modalEl).on('hidden.bs.modal', () => {
        modalVisible.value = false
        emit('modal-hidden')
      })

      $(modalEl).on('shown.bs.modal', () => {
        emit('modal-shown')
      })
    }
  }

  if (!modalVisible.value) {
    modalVisible.value = true

    // show modal on next render
    nextTick(() => {
      const modalEl = modal.value
      if (modalEl) {
        // show modal
        $(modalEl).modal({
          keyboard: props.keyboard
        })
      }
    })
  }
}

const hide = () => {
  const modalEl = modal.value
  if (modalEl) {
    $(modalEl).modal('hide')
  }
}

defineExpose({hide, show})
</script>

<template>
  <div
    ref="modal"
    :class="['modal fade', modalClass]"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <div
      :class="['modal-dialog', modalDialogClass]"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5
            id="modalLabel"
            class="modal-title"
          >
            {{ modalTitle }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <slot name="modal-content" />
        </div>
        <div class="modal-footer">
          <slot name="modal-footer">
            <button
              type="button"
              class="btn btn-sm btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
