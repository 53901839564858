<template>
  <span>
    <button
      type="button"
      :class="['btn btn-light btn-sm', { 'dropdown-toggle': showDropdownCaret }, 'text-truncate filter']"
      data-toggle="dropdown"
      data-flip="false"
      aria-haspopup="true"
      aria-expanded="false"
      :title="value.selectedTitle"
    >
      <slot><span v-html="value.title" />: {{ value.selectedTitle }}</slot>
    </button>
    <div
      class="dropdown-menu filter list"
    >
      <template
        v-for="(item, itemIdx) in value.listItems"
        :key="itemIdx"
      >
        <div
          v-if="item.type == 'separator'"
          class="dropdown-divider"
        />
        <a
          v-else
          class="dropdown-item text-truncate"
          href="#"
          :title="item.title"
          @click.prevent="selectItem($event, item)"
        >
          <span
            :class="{ 'glyphicons checkmark': true, invisible: !isSelected(item) }"
          />
          {{ item.title }}
        </a>
      </template>
      <div
        v-if="value.multiple"
        class="apply-wrap"
      >
        <hr>
        <button
          class="btn btn-sm btn-primary"
          @click="applyFilter"
        >
          Apply
        </button>
      </div>
    </div>
  </span>
</template>
<script lang="ts">
import {defineComponent} from 'vue'
import $ from 'jquery';
import { isNonEmptyString } from '../js-utils'
import type { GridFilter, GridFilterListItem } from './types'

const getSelectedValues = function (newValue: string | string[]): string[] {
  let result: string[] = []
  if (Array.isArray(newValue)) {
    result = newValue.slice()
  } else {
    result = [newValue]
  }
  if (result.length == 0) {
    result = ['']
  }
  return result
}

export default defineComponent({
  props: {
    showDropdownCaret: { type: Boolean, default: true },
    value: { type: Object as () => GridFilter, required: true }
  },

  data() {
    return {
      selectedValues: getSelectedValues(this.value.selectedValues)
    }
  },

  watch: {
    'value.selectedValue': {
      deep: true,
      handler(newValue: string | string[]): void {
        this.selectedValues = getSelectedValues(newValue)
      }
    }
  },

  mounted() {
    $(this.$el).on('show.bs.dropdown', () => {
      // reset selected values
      this.selectedValues = getSelectedValues(this.value.selectedValue)
    })
  },

  methods: {
    selectItem (e: Event, item: GridFilterListItem): void {
      if (this.value.multiple) {
        if (isNonEmptyString(item.value)) {
          e.stopPropagation()

          const elIdx = this.selectedValues.indexOf(item.value)
          if (elIdx == -1) {
            if (isNonEmptyString(item.value)) {
              // remove empty value, if it's in the list of selected
              this.selectedValues = this.selectedValues.filter((value) => isNonEmptyString(value))
            }
            this.selectedValues.push(item.value)
          } else {
            this.selectedValues.splice(elIdx, 1)
            if (this.selectedValues.length == 0) {
              this.selectedValues = ['']
            }
          }
        } else {
          // 'Any' item selected, clear all values
          this.selectedValues = ['']
          this.applyFilter()
        }
      } else {
        this.selectedValues = [item.value]
        this.applyFilter()
      }
    },

    applyFilter(): void {
      this.$emit('input', this.selectedValues)
    },

    isSelected(item: GridFilterListItem): boolean {
      return this.selectedValues.indexOf(item.value) != -1
    }
  }
})
</script>
