import { isNonEmptyString, isBlank } from "@/js/js-utils";

export type GridID = number | string;

export interface ItemAction {
  type: 'url' | 'action' | 'separator';
  method?: string;
  confirm?: string;
  url?: string;
  link?: string;
  title: string;
  name?: string;
}

export interface GridItemAction {
  type: 'url' | 'action' | 'separator' | 'dropdown';
  url?: string;
  link?: string;
  name?: string;
  title: string | null;
  cssClass?: string;
  options?: Record<string, any>;
  subActions: GridItemAction[] | null;
}

export enum GridActionType {
  url = 1,
  action = 2,
  separator = 3,
  dropdown = 4,
}

export interface GridAction {
  type: GridActionType;
  url: string;
  title: string | null;
  cssClass: string;
  options?: Record<string, any>;
  name: string;
}

export interface StringHash {
  [index: string]: string;
}

export interface GridItem {
  id: string;
  meta?: {
    actions?: ItemAction[];
    actionTitle?: string;
  };
  attributes?: any;
  links?: StringHash;
}

export interface GridHeaderAction {
  title: string;
  name?: string;
  type: string;
  url: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  sub_actions?: GridHeaderAction[];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  css_class?: string;
  cssClass?: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  only_with_selected_items?: boolean;
  onlyWithSelectedItems?: boolean;
}

export type CertificateElementType = 'text' | 'image' | 'portfolio-image';
export type CertificateElementAlignment = 'left' | 'right' | 'center';

export interface CertificateElement {
  id?: number;
  type: CertificateElementType;
  alignment?: CertificateElementAlignment;
  fontStyle?: string;
  fontSize?: number | null;
  text?: string | null;
  imageId?: string | null;
  imageUrl?: string | null;
  imagePdfWidth?: number | null;
  imagePdfHeight?: number | null;
  w?: number | null;
  h?: number | null;
  x: number;
  y: number;
}

export interface UploadedPdfImage {
  id: string;
  filename: string;
  size: number;
  pdfWidth: number;
  pdfHeight: number;
  url: string;
}

export interface EventTopicLogo {
  attributes: {
    title: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    logo_url: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    logo_id: number;
  };
  links: {
    update: string;
  };
}

export interface GridFilterListItem {
  value: string;
  title: string;
  type: string;
}

export enum ButtonActionType {
  url = 1,
  action = 2,
  separator = 3,
}

export interface ButtonAction {
  type: ButtonActionType;
  url: string;
  title: string;
  name: string;
  cssClass: string;
  onlyWithSelectedItems: boolean;
}

export interface ReimbursementFile {
  id: string;
  rm: boolean;
  title: string;
  filename: string;
  hasFile: boolean;
  titlePlaceholder: string;
}

export enum GridFilterType {
  text = 1,
  date = 2,
  dateRange = 3,
  list = 4,
  autocomplete = 5,
  unknown = 999,
}

export class GridFilter {
  _selectedValue: string[];
  _additionalListItems: GridFilterListItem[];

  readonly type: GridFilterType;
  readonly name: string;
  readonly title: string;
  readonly emptyTitle: string | null;
  readonly notice: string | null;
  readonly optional: boolean;
  readonly listItems: GridFilterListItem[];
  readonly autocompleteUrl: string | null;
  readonly multiple: boolean;
  readonly placeholder: string;
  readonly isValid: boolean;

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(attributes?: any) {
    this._selectedValue = []
    this._additionalListItems = []

    this.type = GridFilterType.unknown
    this.multiple = false
    this.autocompleteUrl = null
    this.optional = attributes?.optional == false ? false : true
    this.emptyTitle = isNonEmptyString(attributes?.emptyTitle) ? attributes?.emptyTitle : null
    this.notice = isNonEmptyString(attributes?.notice) ? attributes?.notice : null
    this.listItems = []
    this.isValid = true
    this.placeholder = isNonEmptyString(attributes?.placeholder) ? attributes?.placeholder : ''

    if (isNonEmptyString(attributes?.type)) {
      switch(attributes.type) {
        case 'text':
          this.type = GridFilterType.text
          if (isBlank(this.placeholder)) {
            this.placeholder = 'Enter search phrase'
          }
          break
        case 'date':
          this.type = GridFilterType.date
          break
        case 'daterange':
          this.type = GridFilterType.dateRange
          this.multiple = true
          break
        case 'list':
          this.type = GridFilterType.list
          this.multiple = attributes.multiple == true
          // this.items = attributes.items
          break
        case 'autocomplete':
          this.type = GridFilterType.autocomplete
          this.multiple = attributes.multiple == true
          this.autocompleteUrl = attributes.autocompleteUrl
          // this.items = attributes.items
          break
        default:
          this.isValid = false
      }
    }

    this.name = isNonEmptyString(attributes?.name) ? attributes.name : ''
    this.title = isNonEmptyString(attributes?.title) ? attributes.title : ''
    if (this.isValid && isBlank(this.name)) {
      this.isValid = false
    }
    this.selectedValue = attributes?.selectedValue

    if (Array.isArray(attributes?.items)) {
      this.listItems = attributes.items
    }
  }

  get selectedValue(): string | string[] {
    if (this.isDateRange) {
      return [
        this._selectedValue.length > 0 ? this._selectedValue[0] : '',
        this._selectedValue.length > 1 ? this._selectedValue[1] : '',
      ]
    }
    if (this.multiple) {
      return this._selectedValue
    }

    if (this._selectedValue.length > 0) {
      return this._selectedValue[0]
    }

    return ''
  }

  set selectedValue(value: string | string[]) {
    if (!Array.isArray(value)) {
      value = [value]
    }

    this._selectedValue = value
  }

  get selectedTitle(): string {
    if (this.isList || this.isAutocomplete) {
      let allItems:GridFilterListItem[] = []
      if (Array.isArray(this.listItems)) {
        allItems = allItems.concat(this.listItems)
      }
      allItems = allItems.concat(this._additionalListItems)

      const selectedTitles = allItems
        .filter((item) => this._selectedValue.includes(item.value))
        .map((item) => item.title)
        .filter((title) => isNonEmptyString(title))
        .join(', ')

      if (isNonEmptyString(selectedTitles)) {
        return selectedTitles
      }

      const emptyItem = this.listItems.find((item) => item.value == null || item.value == '')
      if (emptyItem && isNonEmptyString(emptyItem.title)) {
        return emptyItem.title
      }
    } else if (this.isDateRange) {
      const dv = this._selectedValue.filter((v) => isNonEmptyString(v))
      if (dv.length > 0) {
        const strDates = dv.map( d => isNonEmptyString(d) ? d : '...')
        return strDates.join(' - ')
      }
    }

    if (this._selectedValue.length > 0 && isNonEmptyString(this._selectedValue[0])) {
      return this._selectedValue[0]
    }

    return this.emptyTitle || ''
  }

  get isText(): boolean {
    return this.type == GridFilterType.text
  }

  get isDate(): boolean {
    return this.type == GridFilterType.date
  }

  get isDateRange(): boolean {
    return this.type == GridFilterType.dateRange
  }

  get isList(): boolean {
    return this.type == GridFilterType.list
  }

  get isAutocomplete(): boolean {
    return this.type == GridFilterType.autocomplete
  }

  set additionalListItems(items: GridFilterListItem[]) {
    this._additionalListItems = items
  }

  get additionalListItems(): GridFilterListItem[] {
    return this._additionalListItems
  }

  get selectedValues(): string[] {
    return this._selectedValue
  }

  get hasValue(): boolean {
    const v = this.selectedValue
    if (Array.isArray(v)) {
      return v.filter((val) => isNonEmptyString(val)).length > 0
    }

    return isNonEmptyString(v)
  }
}

export type ConfirmationConfigurationType = 'danger' | 'primary' | 'info';

export function string2ConfirmationConfigurationType(value: string | undefined): ConfirmationConfigurationType | null {
  switch(value) {
    case 'danger':
    case 'primary':
    case 'info':
      return value
  }

  return null
}

export interface ConfirmationConfiguration {
  title: string;
  message: string;
  type: ConfirmationConfigurationType;
  method: string;
  cancelActionTitle: string;
  confirmActionTitle: string;
  confirmationCode: string | null;
  context: any;
}

export enum EventDthDocumentType {
  file = 1,
  link = 2
}

export interface EventDthDocument {
  filename?: string;
  url: string;
  id: string | null;
  typeId: EventDthDocumentType;
  categoryId: number | null;
  categoryName: string | null;
  destroy: boolean;
  title: string | null;
}

export interface CancellationReason {
  id: string;
  title: string;
  requireComment: boolean;
}
