<template>
  <div
    ref="modal"
    :class="['modal fade modal-form', modalClass]"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <div
      :class="['modal-dialog', modalDialogClass]"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5
            id="modalLabel"
            class="modal-title"
          >
            {{ computedModalTitle }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div
            v-if="error"
            class="alert alert-danger"
            role="alert"
          >
            {{ error }}
          </div>
          <loading-indicator
            v-if="isLoading && !loadingIndicatorInFooter"
            :message="loadingMessage"
          />
          <slot
            name="modal-content"
            :modal-state="modalState"
          >
            <div
              v-if="modalForm && formHtml != null"
              ref="formWrapper"
              v-html="formHtml"
            />
          </slot>
        </div>
        <div class="modal-footer">
          <slot
            name="modal-footer"
            :modal-state="modalState"
          >
            <button
              v-if="deleteUrl && modalForm && formHtml != null"
              type="button"
              class="btn btn-sm btn-danger mr-auto"
              @click="showDeleteConfirmation"
            >
              {{ deleteTitle || 'Delete' }}
            </button>
            <button
              type="button"
              class="btn btn-sm btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              v-if="modalForm && formHtml != null && submitButton"
              class="btn btn-sm btn-primary"
              :disabled="submittingForm"
              @click.prevent="submitForm"
            >
              {{ submitTitle }}
            </button>
          </slot>
        </div>
        <loading-indicator
          v-if="isLoading && loadingIndicatorInFooter"
          :message="loadingMessage"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import $ from 'jquery'
import Rails from "@rails/ujs"
import axios, { AxiosRequestConfig } from 'axios'
import LoadingIndicator from './loading-indicator.vue'
import { isNonEmptyString } from '@/js/js-utils'
import { initializeFormFields } from '../site-utils'

interface ModalState {
  initialDataLoaded: boolean;
  isLoading: boolean;
}

export interface DeleteConfirmation {
  url: string,
  title: string,
  message: string,
}

export default defineComponent({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  components: { LoadingIndicator },
  props: {
    modalTitle: { default: '', type: String },
    modalClass: { default: null, type: String },
    modalDialogClass: { default: null, type: String },
    loadingMessage: { default: null, type: String },
    loadingIndicatorInFooter: { default: true, type: Boolean },
    hideContentWhenLoading: { default: false, type: Boolean },
    allowKeyboard: { default: false, type: Boolean },
    modalForm: { default: false, type: Boolean },
  },
  data () {
    return {
      modalVisible: false,
      isLoading: false,
      error: null as null | string,
      modalEventsAttached: false,
      loadParams: null as any,
      loadUrl: null as any,
      initialLoadPerformed: false,
      formHtml: null as string | null,
      submittingForm: false,
      deleteTitle: null as string | null,
      deleteUrl: null as string | null,
      deleteConfirmationTitle: null as string | null,
      deleteConfirmationMessage: null as string | null,
      customModalTitle: null as string | null,
      customSubmitTitle: null as string | null,
      submitButton: true,
      closeUrl: null as string | null,
    }
  },
  computed: {
    modalState(): ModalState {
      return {
        initialDataLoaded: this.initialLoadPerformed,
        isLoading: this.isLoading,
      }
    },
    computedModalTitle(): string {
      if (isNonEmptyString(this.customModalTitle)) {
        return this.customModalTitle
      }

      return this.$props.modalTitle
    },

    submitTitle(): string {
      if (isNonEmptyString(this.customSubmitTitle)) {
        return this.customSubmitTitle
      }

      return 'Save'
    }
  },
  methods: {
    showLoadingIndicator (show: boolean): void {
      this.isLoading = show
    },

    setError(error: string | null): void {
      this.error = error
    },

    showModal (loadUrl: string, params?: any, requestConfig?: AxiosRequestConfig): void {
      if (!this.modalVisible) {
        this.error = null
        this.isLoading = false

        this.loadUrl = loadUrl
        this.loadParams = params
        this.initialLoadPerformed = false

        this.formHtml = null

        // show modal on next render
        this.$nextTick(() => {
          if (!this.modalEventsAttached) {
            this.modalEventsAttached = true

            $(this.$refs.modal).on('hidden.bs.modal', () => {
              this.modalVisible = false
              this.$emit('modal-hidden', this.closeUrl)
            })
            $(this.$refs.modal).on('shown.bs.modal', () => {
              this.modalVisible = true
              this.$emit('modal-shown')
              this.loadDetails(this.loadUrl, this.loadParams, requestConfig)
            })
          }
          // show modal
          $(this.$refs.modal).modal({
            // backdrop: false,
            keyboard: this.allowKeyboard
          })
        })
      }
    },

    hideModal (): void {
      $(this.$refs.modal).modal('hide')
    },

    loadDetails (loadUrl: string | null, params?: any, reqConfig?: AxiosRequestConfig): void {
      this.error = null

      if (typeof loadUrl === 'string' && loadUrl.length > 0) {
        this.showLoadingIndicator(true)
        const defaultRequestConfig = {} as AxiosRequestConfig
        if (this.modalForm) {
          defaultRequestConfig['headers'] = {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Accept': 'application/json',
          }
        }
        const requestConfig: AxiosRequestConfig = Object.assign({}, reqConfig || defaultRequestConfig)
        if (params) {
          requestConfig.params = params
        }

        axios.get(loadUrl, requestConfig)
          .then((response) => {
            this.showLoadingIndicator(false)
            this.initialLoadPerformed = true

            this.setupForm(response.data)
            this.$emit('details-loaded', response.data)
          })
          .catch((error) => {
            this.error = error.message
            this.showLoadingIndicator(false)
            this.initialLoadPerformed = true
            this.$emit('details-load-error', this.error)
          })
      }
    },

    submitForm(): void {
      // pass
      if (this.$refs.formWrapper) {
        const formEl = this.$refs.formWrapper.querySelector(':scope form')
        if (formEl instanceof HTMLFormElement) {
          Rails.fire(formEl, 'submit')
        }
      }
    },

    setupForm(responseData: any): void {
      if (this.modalForm) {
        this.formHtml = isNonEmptyString(responseData?.html) ? responseData.html : null
        this.customModalTitle = isNonEmptyString(responseData?.modal_title) ? responseData.modal_title : null
        this.customSubmitTitle = isNonEmptyString(responseData?.submit_title) ? responseData.submit_title : null
        this.deleteUrl = responseData?.delete?.url || null
        this.deleteTitle = responseData?.delete?.title || null
        this.deleteConfirmationTitle = responseData?.delete?.confirmationTitle || null
        this.deleteConfirmationMessage = responseData?.delete?.confirmationMessage || null
        this.submitButton = responseData?.submit_button !== false
        this.closeUrl = isNonEmptyString(responseData?.modal_close_url) ? responseData.modal_close_url : null

        this.$nextTick(() => {
          if (this.$refs.formWrapper) {
            this.initForm()
          }
        })
      }
    },

    initForm(): void {
      if (this.$refs.formWrapper) {
        const formEl = this.$refs.formWrapper.querySelector(':scope form')
        if (formEl instanceof HTMLFormElement && formEl.getAttribute('data-form-initialized') !== 'true') {
          formEl.addEventListener('submit', (Rails as any).handleRemote)

          // need to set initialized attribute, so attachment to events
          // does not happen multiple times if formHTML does not changes
          // between the requests
          formEl.setAttribute('data-form-initialized', 'true')

          // force request to be json
          formEl.setAttribute('data-type', 'json')

          // initialize form
          initializeFormFields(formEl)

          // attach to events
          formEl.addEventListener('ajax:beforeSend', () => {
            this.showLoadingIndicator(true)
            this.submittingForm = true
            return true
          })
          formEl.addEventListener('ajax:success', (e) => {
            this.submittingForm = false
            this.showLoadingIndicator(false)

            const [data] = (e as any).detail
            if (data.status == 'success') {
              // form operation succeeded
              this.$emit('form-success')
              return
            }
            this.setupForm(data)
          })
          formEl.addEventListener('ajax:error', (e) => {
            this.submittingForm = false

            const [, status, xhr] = (e as any).detail
            alert(`Error: ${xhr.status} - ${status}`)
            this.showLoadingIndicator(false)
          })
        }
      }
    },

    showDeleteConfirmation(): void {
      this.$emit('delete-confirmation', {
        url: this.deleteUrl,
        title: this.deleteConfirmationTitle || 'Delete record?',
        message: this.deleteConfirmationMessage || 'Are you sure?'
      } as DeleteConfirmation)
    }
  }
})
</script>
