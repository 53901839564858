import axios from 'axios'
import { isNonEmptyString, isEmptyOrNotString } from './js-utils'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getAxiosError(error: any): string | null {
  if (axios.isCancel(error)) {
    return null
  }

  let errorMessage = null
  if (error.response && error.response.data) {
    const errors = error.response.data.errors
    if (Array.isArray(errors) && errors.length > 0 && isNonEmptyString(errors[0].title)) {
      errorMessage = errors[0].title
    } else if (isNonEmptyString(error.response.data.error)) {
      errorMessage = error.response.data.error
    }
  }
  if (isEmptyOrNotString(errorMessage)) {
    if (error && isNonEmptyString(error.message)) {
      errorMessage = error.message
    } else {
      errorMessage = 'An error occured'
    }
  }

  return errorMessage
}
