<template>
  <div class="upload-field">
    <input
      :value="file.filename"
      type="text"
      class="form-control form-control-sm file-name"
      readonly
    >
    <upload-button
      :upload-url="uploadUrl"
      @uploaded="onFileUploaded"
    />
    <a
      :class="['btn btn-sm btn-info', hasFile || 'disabled']"
      :href="file.url"
    >
      Download file
    </a>
    <button
      type="button"
      class="btn btn-sm btn-danger"
      :disabled="!hasFile"
      @click.prevent="onRemoveFile"
    >
      Remove file
    </button>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue"

  import uploadButton from "./upload-button.vue"
  import { isNonEmptyString } from "@/js/js-utils"

  export default defineComponent({
    components: { uploadButton },
    props: {
      uploadUrl: { default: null, type: String },
    },

    data () {
      return {
        valueField: null as HTMLInputElement | null,
        file: {
          filename: '',
          url: '',
          id: '',
        }
      }
    },

    computed: {
      hasFile(): boolean {
        return isNonEmptyString(this.file.id)
      }
    },

    watch: {
      file: {
        deep: true,
        handler(value): void {
          if (this.valueField) {
            this.valueField.value = JSON.stringify({
              id: value.id,
              filename: value.filename,
              url: value.url
            })
          }
        }
      }
    },

    mounted() {
      const valEl = this.$el.parentElement?.parentElement?.querySelector('input[ref="fileId"]')
      if (valEl instanceof HTMLInputElement) {
        this.valueField = valEl
        if (isNonEmptyString(this.valueField.value)) {
          try {
            const defaultValue = JSON.parse(this.valueField.value)
            this.file.id = defaultValue.id
            this.file.filename = defaultValue.filename,
            this.file.url = defaultValue.url
          } catch {}
        }
      }
    },

    methods: {
      onFileUploaded(data: any): void {
        this.file.id = data.file.id
        this.file.filename = data.file.filename
        this.file.url = data.file.url
      },

      onRemoveFile(): void {
        if (confirm("Are you sure?")) {
          this.file.id = ''
          this.file.filename = ''
          this.file.url = ''
        }
      }
    }
  })
</script>
