<template>
  <div
    v-if="showActivities"
    class="participant-activity-manager mt-2 mb-4"
  >
    <h4>Curricular activities</h4>
    <div class="row">
      <div
        v-for="activityDay in eventActivities"
        :key="activityDay.date"
        class="col-lg-6"
      >
        <div class="card day">
          <div class="card-header">
            Day: {{ activityDay.date }}
          </div>
          <ul class="list-group list-group-flush">
            <li
              v-for="activity in activityDay.activities"
              :key="activity.id"
              class="list-group-item"
            >
              <div class="custom-control custom-checkbox custom-control-inline">
                <input
                  :id="activityCbElId(activity)"
                  v-model="activity.selected"
                  type="checkbox"
                  class="custom-control-input"
                  :disabled="readonly"
                >
                <label
                  :for="activityCbElId(activity)"
                  class="custom-control-label"
                >
                  {{ fNameWithPrice(activity) }}
                </label>
              </div>
              <div
                v-if="showDetails(activity)"
                class="activity-details"
              >
                <div
                  v-if="activity.showDescription"
                  v-html="activity.description"
                />
                <div
                  v-if="showNotes(activity)"
                  class="form-group"
                >
                  <label>Notes (optional)</label>
                  <textarea
                    v-model="activity.notes"
                    class="form-control"
                    :readonly="readonly"
                  />
                </div>
              </div>
              <input
                v-model="activity.id"
                type="hidden"
                name="event_candidate[activities_attributes][][activity_id]"
              >
              <input
                v-model="activity.selected"
                type="hidden"
                name="event_candidate[activities_attributes][][selected]"
              >
              <input
                v-model="activity.notes"
                type="hidden"
                name="event_candidate[activities_attributes][][notes]"
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue"
  import { isNonEmptyString } from "../js-utils"

  interface RegistrationActivity {
    id: number;
    priceTypeId: number;
    name: string;
    price: string;
    selected: boolean;
    allowNotes: boolean;
    showDescription: boolean;
    description: string;
    notes: string;
  }

  interface ActivityDay {
    date: string;
    activities: RegistrationActivity[];
  }

  export default defineComponent({
    name: 'CurricularActivityRegistration',
    props: {
      readonly: { default: false, type: Boolean },
      valueFieldRef: { type: String, default: null },
    },
    data () {
      return {
        eventActivities: [] as ActivityDay[],
        valueFieldEl: null as HTMLInputElement | null,
      }
    },
    computed: {
      showActivities (): boolean {
        return Array.isArray(this.eventActivities) && this.eventActivities.length > 0
      }
    },

    watch: {
      eventActivities: {
        deep: true,

        // We have to move our method to a handler field
        handler(): void {
          this.storeActivityData()
        }
      }
    },

    mounted () {
      if (isNonEmptyString(this.valueFieldRef)) {
        const field = document.querySelector(`input[ref="${this.valueFieldRef}"]`)
        if (field instanceof HTMLInputElement) {
          this.valueFieldEl = field
          try {
            const activityDays = JSON.parse(this.valueFieldEl.value)
            if (Array.isArray(activityDays)) {
              for (const dayData of activityDays) {
                if (isNonEmptyString(dayData.date) && Array.isArray(dayData.activities) && dayData.activities.length > 0) {
                  this.eventActivities.push(dayData)
                }
              }
            }
          } catch {}
        }
      }
    },

    methods: {
      activityCbElId (activity: RegistrationActivity): string {
        return `event-activity-${activity.id}`
      },

      showNotes (activity: RegistrationActivity): boolean {
        return activity.selected && activity.allowNotes
      },

      showDetails (activity: RegistrationActivity): boolean {
        return activity.selected && (activity.allowNotes || activity.showDescription)
      },

      storeActivityData(): void {
        if (this.valueFieldEl) {
          this.valueFieldEl.value = JSON.stringify(this.eventActivities)
        }
      },

      fNameWithPrice (activity: RegistrationActivity): string {
        if (activity.priceTypeId == 1) {
          let price = null as number | null
          if (typeof activity.price == 'string') {
            price = parseFloat(activity.price)
          } else if (typeof activity.price == 'number') {
            price = activity.price
          }
          if ( price && !isNaN(price) && isFinite(price) && price > 0) {
            return `${activity.name} (${price.toFixed(2)} €)`
          } else {
            return `${activity.name} (--- €)`
          }
        } else if (activity.priceTypeId == 2) {
          return `${activity.name} (free)`
        } else {
          return activity.name || ''
        }
      }
    }
  })
</script>
