<template>
  <div class="upload-progress">
    <div class="progress">
      <div
        class="progress-bar"
        role="progressbar"
        :style="progressBarStyle"
        :aria-valuenow="progressValue"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        {{ progressValue }}%
      </div>
    </div>
    <small class="text-muted status-label">
      {{ message }}
    </small>
  </div>
</template>
<script lang="ts">
import {defineComponent} from 'vue'

  export default defineComponent({
    props: {
      progress: { default: 0, type: Number },
      message: { default: null, type: String },
    },
    computed: {
      progressValue(): string {
        return this.progress.toFixed(2)
      },
      progressBarStyle(): any {
        return {
          width: `${ this.progress }%`,
          'min-width': '3rem'
        }
      }
    }
  })
</script>
