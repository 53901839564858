<template>
  <div>
    <div
      v-if="files.length > 0"
      class="files-list"
    >
      <div
        v-for="(file, fileIdx) of files"
        :key="file.id"
        class="row mb-2"
      >
        <div class="col col-auto">
          <input
            v-model="file.filename"
            type="text"
            class="form-control form-control-sm"
            readonly
          >
        </div>
        <div class="col col-tight">
          <div class="text-nowrap">
            <a
              v-if="canDownload(file)"
              class="btn btn-sm btn-info mr-2"
              target="_blank"
              :href="file.url"
            >
              Download
            </a>
            <button
              type="button"
              class="btn btn-sm btn-danger"
              @click.prevent="showRemoveFilePrompt(fileIdx)"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3">
      <label :class="['btn btn-sm btn-success', { 'with-upload-icon': !isUploading, 'disabled': !canUpload || isUploading }]">
        <template v-if="isUploading">
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
          {{ uploadProgressMessage }}
        </template>
        <template v-else>
          Add new file
          <input
            type="file"
            style="display: none"
            :disabled="!canUpload || isUploading"
            @change="uploadApplicationFile"
          >
        </template>
      </label>
    </div>

    <teleport
      to="#vue-portal-target"
      append
    >
      <modal-confirmation-rails
        ref="removeFileConfirmationModal"
        modal-title="Delete file?"
        @confirmed="onRemoveFileConfirmed"
      />
    </teleport>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
  import axios from 'axios'
  import Rails from '@rails/ujs'

  import ModalConfirmationRails from './modal-confirmation-rails.vue'
  import { isNonEmptyString } from '../js-utils'

  interface UploadFile {
    id: string;
    title: string;
    filename: string;
    url?: string;
  }

  export default defineComponent({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    components: { ModalConfirmationRails },
    props: {
      numberOfFiles: { type: Number, default: 5 },
      uploadUrl: { type: String, default: null },
    },
    data () {
      return {
        isUploading: false,
        uploadProgress: 0,
        uploadProgressMessage: '',
        uploadError: null as string | null,
        files: [] as UploadFile[],
        filesJsonEl: null as null | HTMLInputElement
      }
    },
    computed: {
      canUpload (): boolean {
        return typeof this.uploadUrl === 'string' && this.uploadUrl.length > 0
      }
    },
    watch: {
      files: {
        deep: true,

        // We have to move our method to a handler field
        handler(): void {
          this.storeApplicationFiles()
        }
      }
    },
    mounted () {
      // const jsonEl = document.querySelector('input[ref="files"]')
      const jsonEl = this.$el.parentElement?.parentElement?.querySelector('input[ref="files"]')
      if (jsonEl instanceof HTMLInputElement) {
        this.filesJsonEl = jsonEl
        try {
          const filesData = JSON.parse(this.filesJsonEl.value)
          if (Array.isArray(filesData)) {
            this.files = filesData
          }
        } catch {
        }
      }
    },
    methods: {
      uploadApplicationFile (e: Event): void {
        if (e.target instanceof HTMLInputElement) {
          this.uploadFile(e.target)
        }
      },

      uploadFile (uploadField: HTMLInputElement): void {
        if (!this.canUpload) {
          return
        }

        if (!uploadField.files) {
          return
        }

        const uploadFile = uploadField.files.item(0)
        if (uploadFile === null) {
          // IE 11 will fire change event when setting uploadField.value to ''
          // if file is null, then do not anything
          return
        }

        const formData = new FormData()
        formData.append('file', uploadFile)

        // IE 11 will fire change event again
        uploadField.value = ''

        this.isUploading = true
        this.uploadProgress = 0
        this.uploadProgressMessage = 'Uploading file ...'
        this.uploadError = null

        axios.post(this.uploadUrl, formData, {
          headers: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'X-Requested-With': 'XMLHttpRequest',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'X-CSRF-Token': Rails.csrfToken()
          },
          onUploadProgress: (progressEvent) => {
            this.uploadProgress = Math.floor(progressEvent.loaded / progressEvent.total * 100)
            if (progressEvent.loaded === progressEvent.total) {
              this.uploadProgressMessage = 'Processing file ...'
            } else {
              this.uploadProgressMessage = `Uploading file: ${this.uploadProgress} %`
            }
          }
        })
          .then((response) => {
            if (response.data) {
              if (response.data.file) {
                // check if we have a file with the same id
                const fileId = response.data.file.id
                let fileUpdated = false
                for (let i = 0; i < this.files.length; i++) {
                  if (this.files[i].id === fileId) {
                    this.files.splice(i, 1, response.data.file)
                    fileUpdated = true
                  }
                }
                if (!fileUpdated) {
                  this.files.push(response.data.file)
                }
              } else if (response.data.error) {
                this.uploadError = response.data.error
              } else {
                this.uploadError = 'Invalid response from server'
              }
            }
            this.isUploading = false
          })
          .catch((error) => {
            this.uploadError = error.message
            this.isUploading = false
          })
      },

      storeApplicationFiles (): void {
        if (this.filesJsonEl) {
          this.filesJsonEl.value = JSON.stringify(this.files)
        }
      },

      showRemoveFilePrompt(fileIdx: number): void {
        this.$refs.removeFileConfirmationModal.show(null, {
          title: 'Remove file?',
          message: 'Are you sure you want to remove this file?',
          type: 'danger',
          cancelActionTitle: 'Cancel',
          confirmActionTitle: 'Remove',
          confirmationCode: null,
          context: fileIdx
        })
      },

      onRemoveFileConfirmed(fileIdx?: number): void {
        this.$refs.removeFileConfirmationModal.hide()
        if (typeof fileIdx == 'number' && fileIdx >= 0 && fileIdx < this.files.length) {
          this.files.splice(fileIdx, 1)
        }
      },

      canDownload(file: UploadFile): boolean {
        return isNonEmptyString(file.url)
      }
    }
  })
</script>
