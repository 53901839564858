<script setup lang="ts">
import { isNonEmptyString } from '../js-utils'
import {computed} from "vue";

export interface Props {
  message?: string | null
  additionalCssClass?: string
  inline?: boolean
  vertical?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  message: null,
  additionalCssClass: "",
  inline: false,
  vertical: false
})

const showMessage = computed((): boolean => isNonEmptyString(props.message))
</script>

<template>
  <div :class="['loading-indicator-wrap', inline ? 'inline' : null, additionalCssClass]">
    <div :class="['d-flex align-items-center justify-content-center', { 'flex-column': vertical }]">
      <div
        class="spinner-border"
        role="status"
        aria-hidden="true"
      />
      <span
        v-if="showMessage"
        class="ml-2"
      >
        {{ message }}
      </span>
    </div>
  </div>
</template>

